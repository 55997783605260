<template>
	<div class="row result-container">
		<div class="row bg-whitesmoke home-block">
			<h2 class="text-uppercase blue col-12 col-lg-9 title-bilan">Bilan de votre autodiagnostic innovation</h2>
			<b-button class="col-10 col-md-7 col-lg-3 bg-orangered btn-bilan" @click="downloadPDF()"><a>Télécharger mon bilan au format PDF <font-awesome-icon style="color: white" class="m-left vertical-center-2 float-right" :icon="['fas','download']" size="lg"/></a></b-button>
			<div class="paragraphe first-paragraphe col-md-12">
				<p class="font-weight-bold">Vous avez terminé votre autodiagnostic Diag Innov.</p>
				<p class="font-weight-bold">Découvrez vos résultats et faites le point sur votre positionnement face à l'innovation.</p>
			</div>
			<div class="paragraphe col-md-12">
				<p class="font-weight-bold">Grâce au numéro que vous avez reçu par e-mail, vous pourrez consulter à nouveau ces éléments, ou simplement effectuer un autre test pour faire à nouveau le point en quelques minutes.</p>
			</div>
			<p class="col-12 col-lg-9 font-weight-bold last-paragraphe text-bilan">Si vous le souhaitez, vous pouvez être mis en relation avec votre Conseiller Formation.</p>
			<b-button @click="openResultMailModal()" class="col-10 col-md-7 col-lg-3 bg-orangered btn-bilan"><a>Être contacté par mon Conseiller Formation <font-awesome-icon style="color: white" class="m-left vertical-center-2  float-right" :icon="['fas','headset']" size="lg"/></a></b-button>
		</div>
		<div class="col-md-12 row block container-round-gray">
			<h5 v-if="mobile" id="contact-block" class="col-md-12 bg-midnightblue result-title-block text-uppercase">Informations</h5>
			<div v-else class="col-md-2 bg-midnightblue" id="block-img-info-contact">
				<div class="white-circle">
					<img class="img-info-contact"  src="../../assets/icons/bilan.svg">
				</div>
			</div>
			<div class="col-12 col-lg-10 bg-whitesmoke">
				<p class="border-left-blue info-contact info-contact-margin"><span class=" font-weight-bold text-uppercase">{{diagnosis.contact.firstName}} {{diagnosis.contact.lastName}}{{diagnosis.contact.job != "" ? ' - ' : ''}}</span>{{diagnosis.contact.job}}</p>
				<p class="border-left-turquoise font-weight-bold text-uppercase info-contact info-contact-margin">{{diagnosis.contact.company}}</p>
				<p class="border-left-orange text-uppercase info-contact"><span class=" font-weight-bold">Activité principale : </span>{{activities}}</p>
				<p class="float-right" id="text-date"><font-awesome-icon class="m-left vertical-center-2 blue" :icon="['fas','calendar-alt']" size="lg"/> {{diagnosis.date}}</p>
			</div>
		</div>

		<div class="col-md-12 row block bg-whitesmoke container-round-gray">
			<h5 class="col-md-12 bg-midnightblue result-title-block text-uppercase">Bilan</h5>
			<b-table class="col-md-12" :items="bilanTable" :fields="fields" responsive="md" id="table-bilan">
			</b-table>
			<div class="col-md-4"></div>
			<div class="col-md-8 col-sm-12 bareme">
				<p class="text-uppercase">Barème : </p>
				<div class="bareme-bar">
					<span>1</span>
					<span>2</span>
					<span>3</span>
					<span>4</span>
					<span>5</span>
				</div>
				<span class="bareme-text" id="bad">Je dois m'améliorer</span>
				<span class="bareme-text" id="good">Je suis au top !</span>
			</div>
		</div>

		<div class="col-md-12 row block container-round-gray bg-whitesmoke" id="result-chart-block">
			<h5 class="col-md-12 bg-midnightblue result-title-block text-uppercase">Diagramme</h5>
			<div class="col-md-12" id="canvas-block" ref="">
				<canvas id="canvas-result"></canvas>
			</div>
			<canvas id="canvas-export" hidden></canvas>
		</div>

		<div class="col-md-12 row block container-round-gray bg-whitesmoke" id="advice-block">
			<h5 class="col-md-12 bg-midnightblue result-title-block text-uppercase">Quelques premiers conseils génériques</h5>
			<div class="col-lg-8">
				<p class="text-uppercase margin-bottom-text">Pour continuer à progresser, renforcez vos compétences en :</p>
				<ul class="competence-list">
					<li v-for="competence of priorizeCompetence" :key="competence.id" class="text-uppercase bold item-competence">{{competence.name}} : 
						<ul>
							<li v-for="advice of competence.advices" :key="advice.id" class="item-advice"><a v-if="advice.link != ''" :href="advice.link" class="bold" target="_blank">{{advice.text}}</a><span v-else>{{advice.text}}</span></li>
						</ul>
					</li>
					
				</ul>
				<p class="text-uppercase also">MAIS AUSSI : </p>
				<ul class="competence-list">
					<li v-for="competence of secondaryCompetences" :key="competence.id" class="text-uppercase bold item-competence item-competence-secondary">{{competence.name}} 
					</li>
					
				</ul>
			</div>
			<div class="col-lg-4" id="img-advice">
				<div class="white-bordered">
					<img src="../../assets/icons/advice.svg">
				</div>
			</div>
		</div>

		<div class="col-md-12 row block container-round-gray bg-whitesmoke" id="advice-block">
			<h5 class="col-md-12 bg-midnightblue result-title-block text-uppercase">Proposition automatique d'un programme de formation adapté à vos besoins</h5>
			<div class="col-12 col-lg-8 col-xl-9">
				<table id="table-target" class="col-md-12">
					<thead>
						<tr>
							<th class="right width40 text-uppercase">Objectifs de progression : </th>
							<th><font-awesome-icon class="m-left vertical-center-2" :icon="['fas','flag']" size="2x"/></th>
							<th></th>
							<th><font-awesome-icon class="m-left vertical-center-2" :icon="['fas','bullseye']" size="2x"/></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="axis of bilanTable" :key="axis.axis">
							<td class="right">{{axis.axis}}</td>
							<td class="table-bold" :class="axis.personal != 'NC' && axis.priority != 0 ? axis.priority == 1 ? 'lightgreen' : 'green' : ''">{{axis.personal}}</td>
							<td :class="axis.personal != 'NC' && axis.priority != 0 ? axis.priority == 1 ? 'lightgreen' : 'green' : ''"><img class="shadow-arrow" src="../../assets/fleche.svg"></td>
							<td class="table-bold" :class="axis.personal != 'NC' && axis.priority != 0 ? axis.priority == 1 ? 'lightgreen' : 'green' : ''">{{axis.personal == "NC" ? "NC" : axis.target}}</td>
						</tr>
					</tbody>
				</table>
				<div class="row legend-target">
					<div class="col-sm-2 col-lg-4"></div>
					<div class="legend-text col-6 col-sm-5 col-lg-4">
						<div style="display: inline-block;" class="legend-table-target green"></div>
						<p style="display: inline-block;">Objectif prioritaire</p>
					</div>
					<div class="legend-text col-6 col-sm-5 col-lg-4">
						<div style="display: inline-block;" class="legend-table-target lightgreen"></div>
						<p style="display: inline-block;">Objectif secondaire</p>
					</div>
				</div>

				<div class="white-spacer"></div>

				<p class="text-uppercase bold">Exemples de formations</p>
				<ul id="formation-list" v-if="priorizeCompetence[0] != null">
					<li v-for="formation of priorizeCompetence[0].formations" :key="formation.id">{{formation.name}}</li>
				</ul>
			</div>
			<div class="col-12 col-lg-4 col-xl-3">
				<div class="white-bordered text-conseiller">
					<font-awesome-icon id="warning-icon" class="m-left vertical-center-2 orangered" :icon="['fas','exclamation-triangle']" size="4x"/>
					<p id="text-conseiller-bold">Votre conseiller Formation CONSTRUCTYS peut vous accompagner dans l'identification des solutions, des formations, et des prestataires les plus adaptés à vos besoins.</p>
					<p>À titre indicatif, et sur la base de votre autodiagnostic, nous pouvons par exemple d'ores et déjà vous présenter les pistes suivantes, à affiner avec votre conseiller en fonction des particularités de votre entreprise, de votre activité réelle, du budget formation de votre entreprise et de vos attentes.</p>
				</div>
			</div>
		</div>

		<b-toast id="result-mail-toast" title="Contact avec votre Conseiller Formation" toaster="b-toaster-top-center" auto-hide-delay="5000">
		<p v-if="mailSend">Un mail a été envoyé à votre Conseiller Formation</p>
		<p v-else>Nous n'avons pas réussi à envoyer de mail à votre Conseiller Formation</p>
		</b-toast>

		<ResultMailForm ref="resultMailForm" :diagnosis="diagnosis" @notify-result-mail="notifyResultMail"/>
	</div>
</template>

<script>
// @ is an alias to /src
import ResultMailForm from "@/components/form/ResultMailForm";
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "../../api-requests";
import {Barchart} from "../../util/chart-util"

export default {
  name: 'component-diagnosis-result',
  components: {
		ResultMailForm
  },
  props: ['diagnosis'],
  data() {
    return {
		ua: getUA,
		mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
		currentComponent: '',
		activities: "",
		bilanTable: [],
		fields: [
			{key:"axis", label: '', thClass: 'no-back'},
			{key:"personal", label: 'VOTRE POSITIONNEMENT', thClass: 'first-header'},
			{key:"global", label: 'POSITIONNEMENT MOYEN DANS VOTRE DOMAINE D\'ACTIVITé'.toUpperCase(), thClass: 'second-header'}
		],
		chartdata: null,
		chartLoaded: false,
		options: {
			responsive: true,
			barRoundness: 1,
			borderRadius: 10,
			scales: {
				yAxes: [{

					ticks: {
						min: 0,
						max: 5,
						stepSize: 1,
					},
				}],
				xAxes: [{
					borderRadius: 10
				}]
			}
		},
		labels: [],
		datas: [],
		priorizeCompetence: [],
		secondaryCompetences: [],
		alreadyDownloaded: false,
		mailSend:false
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
	},
	contactMail() {
		return this.$store.state.contactMail;
	}
  },
  methods: {
	getActivitiesFromDiagnosis() {
		this.activities = "";
		for(const activity of this.diagnosis.activities) {
			this.activities += activity.name+", ";
		}
		this.activities = this.activities.substring(0, this.activities.length - 2);
	},
	getPositionnings(){
		API.getPositionnings(this.diagnosis).then(response => {
			this.bilanTable = response.data;
			for(let result of this.bilanTable) {
				this.labels.push("AXE " + result.axis);
				this.datas.push([result.personal, result.global]);
				result.axis = "AXE " + result.axis;
				result.personal = !result.result ? "NC" : Math.round(result.personal * 10) / 10;
				result.target = !result.result ? "NC" : Math.round(result.target * 10) / 10;
				result.global = result.global == null ? 0 : Math.round(result.global * 10) / 10;
			}

			this.resizeGraphEvent();
		});
	},
	resizeGraphEvent() {
			let canvasBlock = document.getElementById("canvas-block");
			let canvas = document.getElementById("canvas-result");
			canvas.width = canvasBlock.clientWidth >= 800 ? canvasBlock.clientWidth - 50 : 800;
			canvas.height = canvasBlock.clientWidth >= 800 ? (canvasBlock.clientWidth/2) - 50 : 400;

			var myBarchart = new Barchart(
				{
					canvas,
					padding:50,
					gridScale:1,
					gridMax: 5,
					gridColor:"#707070",
					backgroundColor: "#ffffff",
					legends: ["Votre positionnement".toUpperCase(), "Positionnement moyen dans votre domaine d'activité".toUpperCase()],
					data: this.datas,
					labels: this.labels,
					colors:["#279ce8","#e95e27"]
				}
			);
			myBarchart.draw();
	},
	getAdvices() {
		API.getAdvicesDiagnosis(this.diagnosis.code).then(response => {
			this.priorizeCompetence.push(response.data[0]);

			for(let i = 1; i < 3; i++) {
				this.secondaryCompetences.push(response.data[i]);
			}
		});
	},
	downloadPDF() {
		if(!this.alreadyDownloaded) {
			let canvasServer = document.getElementById("canvas-export");
			canvasServer.width = 1280;
			canvasServer.height = 640;

			var barchartExport = new Barchart(
					{
						canvas: canvasServer,
						padding:50,
						gridScale:1,
						gridMax: 5,
						gridColor:"#707070",
						backgroundColor: "#ffffff",
						legends: ["Votre positionnement".toUpperCase(), "Positionnement moyen dans votre domaine d'activité".toUpperCase()],
						data: this.datas,
						labels: this.labels,
						colors:["#279ce8","#e95e27"]
					}
				);
				barchartExport.draw();
				
			let imageDataURL = canvasServer.toDataURL('image/png');

			this.$bvToast.show('pdf-mail-toast');
			this.alreadyDownloaded = true;
			API.downloadPdf(imageDataURL, this.diagnosis.code).then(() => {});
		}
	},
	getContactMail() {
		this.$store.dispatch('getContactMail');
	},
    openResultMailModal() {
		console.log(this.$refs.resultMailForm);
      this.$refs.resultMailForm.show();
    },
	notifyResultMail(mailSend) {
		this.mailSend = mailSend;
		this.$bvToast.show('result-mail-toast');
	}
  },
  created() {
	window.addEventListener("resize", this.resizeGraphEvent);
	this.getContactMail();
	},
	destroyed() {
	window.removeEventListener("resize", this.resizeGraphEvent);
	},
  mounted() {
	this.getActivitiesFromDiagnosis();
	this.getPositionnings();
	this.getAdvices();
  }
}
</script>

<style lang="scss">
	.paragraphe p, .text-bilan {
		margin:0;
		font-size:1.2rem;
	}

	.paragraphe {
		margin-bottom:10px;
	}

	.btn-pdf{
		margin-top:-26px
	}

	.btn-bilan {
		border:none;
		color:white;
		border-radius:0;
		height: 55px;
		
		svg {
			margin-left: auto;
		}

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			font-family: "Raleway";
		}

		a:hover {
			color: $white;
		}
	}

	.btn-bilan:hover, .btn-bilan:focus, .btn-bilan:active {
		background-color: rgb(221,115,0)!important;
		border:none!important;
		color: white;
	}

	.title-bilan {
		font-size:2rem;
	}

	.no-back {
		border: 0 !important;
	}

	.first-header {
		border: solid 1px white !important;
		border-left: solid 10px rgb(39,156,232) !important;
		background-color: white;
		color:#575757;
		text-align: center;
	}

	.second-header {
		border: solid 1px white !important;
		border-left: solid 10px rgb(233,94,39) !important;
		background-color: white;
		color:#575757;
		text-align: center;
	}

	#table-bilan {
		td {
			border: 0;
			padding:5px;
			color:#575757;
		}

		th {
			width: 33%;
		}

		tr td {
			text-align:center;
			font-weight: 700;
		}

		tr td:first-child {
			text-align:right;
			padding-right:20px;
			font-weight: 400;
		}

		tr:nth-of-type(even) {
			background-color: white;
		}
	}

	.b-dropdown-form{
		padding-right:12px!important;
	}

	.custom-checkbox,.custom-control-label, .custom-control {
		cursor:pointer;
	}
</style>

<style scoped lang="scss">
	#advice-block {
		color:#575757;
		font-size: 1.1em;
		ul {
			.bold {
				font-weight: 600;
			}
			padding:0;
			.item-competence {
				list-style: none;
				padding: 5px 0;
			}

			.item-competence:before {
				margin-left: 4px;
				content: "\2022";
				color: $blue;
				font-weight: bold;
				width: 1em;
				padding-right: 20px;
				font-size: 30px;
				line-height: 0;
			}
			.item-competence-secondary:nth-child(odd) {
				background-color: white;
			}

			.item-advice {
				margin-left: 40px;
				font-weight: 400;
				text-transform:none;
				font-size: 0.9em;
			}
		}

		.margin-bottom-text {
			margin-bottom: 30px;
			font-weight: 500;
		}

		.margin-text {
			margin-left: 30px;
		}
	}

	.result-container {
		margin: 0px 20px;
	}

	#block-img-info-contact {
		text-align: center;

		img{
			width: 9em;
			margin-top: 5%;
		}

		.white-circle{
			margin: auto;
			background-color: white;
			border-radius: 50%;
			height:150px;
			width:150px;
			margin-top: 8px;
		}
	}

	.container-round-gray {
		padding: 0;
		margin:0;
	}

	.block{
		margin: 20px 0px;

	}

	.border-left-blue {
		border-left: 8px solid rgb(39,156,232);
			padding-top:5px;
	}

	.border-left-turquoise {
		border-left: 8px solid rgb(99,233,200);
	}

	.border-left-orange {
		border-left: 8px solid rgb(232,155,51);
	}

	.info-contact {
		padding-left: 20px;
		margin:0;
		font-size: 1.3rem;
		padding-bottom: 10px;
		color: #575757;
	}

	.info-contact-margin {
		margin-bottom: 20px;
	}

	.result-title-block {
		padding:10px;
		color:white;
		font-family: "Raleway";
	}

	.bareme-bar {
		background: rgb(255,0,0);
		background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(214,214,1,1) 50%, rgba(0,255,0,1) 100%);
		height: 25px;

		span {
			color: white;
			margin: 0px 9.5%;
			font-weight:700;
		}
	}

	#result-chart {
		position:relative;
		height: 80vh;
		width:40vw;
	}


	.bareme {
		margin-bottom:2vh;
		p {
			text-align:center;
			color:#575757;
			font-weight: 700;
			font-size:1.5em;
		}

		.bareme-text {
			color:#575757;
			font-weight: 400;
			font-size:1em;
		}
	}

	#good{
		float: right;
	}

	#img-advice {
		img {
			width:60%;
		}
		
	}

	.white-bordered {
		margin: 20px;
		background-color: white;
		text-align: center;
	}



	.text-conseiller{
		p{
			padding: 30px 20px; 
			text-align: left;
			font-size: 0.9em;
		}

		#text-conseiller-bold {
			padding-bottom: 0;
			font-weight: 600;
		}
	}

	.green{
		background-color: rgb(106,234,140);
	}

	.lightgreen{
		background-color: rgb(232,229,63);
	}

	#table-target, #table-priority {
		margin-top:20px;

		.table-bold {
			font-weight: 700;
		}

		thead{
			background-color: $steelblue;
			color: white;
		}

		td {
			padding: 5px;
		}

		.right{
			text-align:right;
			padding-right: 10px;
		}

		th {
			
			padding:5px;
			width: 20%;
		}

		.left {
			text-align:left;
		}

		th,td {
			text-align:center;
			font-size: 0.9em;
		}

		.width40 {
			width: 40%;
		}

		tr:nth-child(even) {
			background-color: white;

			.green{
				background-color: rgb(111,239,145);
			}

			.lightgreen{
				background-color: rgb(237,234,68);
			}
		}
	}

	#table-priority{
		thead{
			background-color: rgb(39,156,232);
			color: white;
		}

		th {
			padding:5px;
		}
	}

	.white-spacer {
		margin: 20px 0px 10px 20px;
		border: solid 2px white;
	}

	.legend-target {
		margin-top:10px;
		p {
			margin: 0;
			padding-left: 10px;
		}
	}

	.legend-table-target{
		width:1.5em;
		height:1.5em;
		border-radius:3px;
	}

	#warning-icon {
		margin-top: 20px;
	}

	#canvas-block{
		overflow: auto;
		::-webkit-scrollbar { 
			display: none;  /* Chrome Safari */
		}
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* IE 10+ */
		}

	#text-date {
		position: absolute;
		right: 1vw;
		top: 1vh;
		font-size: 1.3rem;
	}

	.m-top-30{
		margin-top:30px;
	}

	.shadow-arrow {
		width: 5em;
		-webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
		filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
		/* Similar syntax to box-shadow */
	}

	@media all and (max-width: 768px) {
		.block {
			font-size: 0.8em;
			table {
				font-size: 0.8em;
			}

			.legend-target {
				font-size: 0.7em;
			}

			h5 {
				font-size: 1em;
			}
		}

		#advice-block {
			font-size: 0.9em;
		}

		.competence-list {
			.item-competence {
				margin-left:10px;
				font-size:0.9em;
			}

			.item-competence:before {
				padding-right: 10px;
				font-size: 20px;
			}
		}

		#img-advice {
			.white-bordered {
				margin: 10px 25%;
			}
		}

		.bareme {
			p {
				font-size: 0.9em;
			}
		}

		.info-contact {
			font-size: 1em;
		}

		#text-date {
			font-size:1em;
			margin-top: 10px;
			text-align: right;
			position: relative;
		}

		.paragraphe p, .text-bilan {
			margin:0;
			font-size:1em;
		}

		.title-bilan {
			font-size: 1.5em;
		}

		.btn-bilan {
			margin: auto;
		}

		.bareme-bar {
			span {
				margin: 0px 9%;
			}
		}
	}

	.bold {
		font-weight: 600;
	}

	#formation-list {
		list-style-type: none;

		li {
			margin-left:40px;
		}

		li:before {
			margin-left: 4px;
			content: "\2022";
			color: $blue;
			font-weight: bold;
			width: 1em;
			padding-right: 20px;
			font-size: 30px;
			line-height: 0;
		}
	}

	.also {
		font-weight: 500;
		margin-left:20px;
	}

	@media all and (max-width: 576px) {
		.legend-text {
			padding: 0px 10px;
		}

		#formation-list {
			li {
				margin-left:10px;
				font-size:0.8em;
			}

			li:before {
				padding-right: 10px;
				font-size: 20px;
			}
		}
	}

	#contact-block {
		margin-bottom: 0;
	}

	@media all and (max-width: 992px) {
		#block-img-info-contact {
			border-bottom-left-radius: 0;
			border-top-right-radius: 10px;

			img {
				margin-top:0;
			}
		}
	}

	@media all and (max-width: 768px) {
		.paragraphe {
			font-size: 0.8em;
		}

		.text-bilan {
			font-size: 0.8em;
		}

		.first-paragraphe {
			margin-top:10px;
		}

		.last-paragraphe {
			margin-bottom:10px;
		}
	}
</style>
