<template>
	<div>
		<div class="container-quizz bg-whitesmoke">
			<p class="text-align-center" id="first-text-container"><span class="text-same-block">Démarrons par un <span class="font-weight-bold">quiz rapide sur l'innovation</span> de manière à voir où vous en êtes</span><span class="text-same-block">et prolongeons l'expérience à travers le diagnostic !</span></p>
			<p class="text-align-center"><span class="text-same-block">Vous allez être confrontés à différentes innovations, saurez-vous les situer dans le temps ?</span><span class="text-same-block">Choisissez la bonne date pour entrer dans le monde de l'innovation !</span></p>
		</div>

		<b-button id="button-launch-quizz" class="col-md-8" @click="launchQuizz()">
      <div class="content-button">
        <img src="../../assets/icons/startQuiz.svg" alt="Commencer le quiz"/>
        <span>Commencer le quiz ({{questions.length - 1}} questions)</span>
      </div>
    </b-button>
	</div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";

export default {
  name: 'QuizzHome',
  components: {
  },
  props: ["questions"],
  data() {
    return {
		ua: getUA,
		mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    }
  },
  methods: {
	launchQuizz() {
		this.$emit("launchQuizzEvent");
	}
  }
}
</script>

<style>

</style>

<style scoped lang="scss">
  .container-quizz {
    font-size: 1.2em;
    margin: 20px;
    padding:20px;
    text-align:center;
    color: rgb(112, 112, 112);
    margin-bottom: 50px;
  }

  .text-same-block {
    display:block;
  }

  #first-text-container {
    padding: 0px 10%;
  }

  #button-launch-quizz {
    margin:20px;
    display:block;
    background-color: $steelblue;
    border-color: $steelblue;
    color: white;
    border-radius: 0;
    font-size: 2em;
    text-align:left;
    margin:auto;
    padding:15px;
    box-shadow: 1px 1px 3px 0 #707070;

    img {
      width: 12%;
      padding: 3px;
      border-radius: 50%;
      background-color: white;
    }

    span {
      text-align: center;
      width: 100%;
    }

    .content-button {
      display: flex;
      align-items: center;
    }
  }

  #button-launch-quizz:hover {
    background-color: $steelblueHover;
    border-color: $steelblueHover;
  }

  @media all and (max-width: 960px) {
    #button-launch-quizz {
      font-size:1em;
      padding:10px;
      width:90%;
    }

    .container-quizz{
      font-size:1em;
    }
  }

</style>
