<template>
  <div class="col-md-12">
    <div class="question-box">
      <div v-if="!loading" class="question-number-circle blue">{{diagnosisQuestion.number}}</div>
      <div v-if="!loading">
        <h5 class="text-uppercase question-text">{{diagnosisQuestion.innovation.title}}</h5>
        <p class="question-info">{{diagnosisQuestion.innovation.complementaryInfo}}</p>
      </div>
      <div v-if="!loading" class="img-innovation">
        <div ref="img-question" id="img-question" v-html="picture"></div>
             
      </div>
    </div> 

    <div class="answer-line col-12">
      <img class="img-answer-line d-none d-lg-block"  src="../../assets/icons/bad.svg">
      <b-button :disabled="loading" v-for="answer in possibleAnswers" :key="answer.result" @click="answerQuestion(answer.result)" class="button-answer-line" :class="buttonColor(answer.color)">{{answer.text}}</b-button>
      <img class="img-answer-line d-none d-lg-block"  src="../../assets/icons/good.svg">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "@/api-requests";

export default {
  name: 'component-diagnosis-question',
  components: {
  },
  props: ['diagnosis', 'currentQuestion'],
  watch: {
    currentQuestion: function(val) {
      this.loading = true;
				API.getInnovationPicture(this.diagnosis.diagnosisQuestions[val].innovation.id).then(response => {
          this.picture = response.data;
          this.loading = false;
				});
    },
  },
  data() {
    return {
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
      possibleAnswers: [
        {text: "Je ne connais pas", result: 0, color: "red-2"},
        {text: "Cela me dit quelque chose", result: 1, color: "orangered"},
        {text: "J'ai lu ou vu des informations à ce sujet", result: 2, color: "darkorange"},
        {text: "J'ai pratiqué parfois", result: 3, color: "olivedrab-2"},
        {text: "Je pratique régulièrement", result: 4, color: "olivedrab-1"},
        {text: "Je l'ai complètement intégré à mon entreprise", result: 5, color: "seagreen"},
      ],
      loading: false,
      picture: "",
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    },
    diagnosisQuestion() {
      return this.diagnosis.diagnosisQuestions != null ? this.diagnosis.diagnosisQuestions[this.currentQuestion] : {innovation: {}};
    }
  },
  methods: {
    buttonColor(color) {
      return "bg-"+color+" border-"+color;
    },
    answerQuestion(result) {
      this.diagnosisQuestion.result = result;
      this.diagnosisQuestion.responded = true;
      API.updateQuestionResult(this.diagnosisQuestion).then(() => {
        this.$emit("nextQuestionEvent");
      })
      this.timeout = setTimeout(() => {
      }, 500)
    }
  },
  created() {
    API.getInnovationPicture(this.diagnosis.diagnosisQuestions[this.currentQuestion].innovation.id).then(response => {
          this.picture = response.data;
    });
  }
}
</script>

<style lang="scss">
.question-box {
  border: solid 5px $blue;
  margin:40px 60px;
  box-shadow: 3px 3px 5px lightgrey;
  padding: 20px 40px;
  position:relative;
  display:flex;
  justify-content: space-between;
  height:250px;
}

.question-number-circle {
  position: absolute;
    left: -35px;
    top: -35px;
    width: 4rem;
    height: 4rem;
    border: solid 5px $blue;
    background-color: white;
    border-radius: 50%;
    font-size: 2.2rem;
    text-align: center;
    font-weight: 900;
    color: $blue;
}

.button-answer-line {
  width:13.5%;
  min-height:120px;
  font-size: 1em;
  margin:0.5%;
  border-radius:0;
  box-shadow: 3px 3px 5px lightgrey;
  color: white;
}
.img-answer-line {
  width:5em;
  margin:1%;
}

.question-text {
  color: $darkgray;
  font-size: 2.25em;
}

.question-info {
  font-size:1.5em;
}

@media all and (max-width: 1200px) {
  .question-text {
    font-size: 1.8em;
  }

  .question-info {
    font-size:1.2em;
  }
}

@media all and (max-width: 992px) {
  .question-text {
    font-size: 1.5em;
  }

  .question-info {
    font-size:1em;
  }
}
</style>

<style scoped lang="scss">
#img-question {
  width: 12em;
  height:12em;
}

.bg-red-2 {
	background-color: rgb(216,26,0);
}

.bg-red-2:hover {
	background-color: rgb(196,6,0);
  color:white;
}

.border-red-2 {
	border: solid 1px rgb(216,26,0);
}

.bg-olivedrab-1 {
	background-color: rgb(71,156,42);
}

.bg-olivedrab-1:hover {
	background-color: rgb(61,146,32);
  color:white;
}

.border-olivedrab-1 {
	border: solid 1px rgb(71,156,42);
}

.bg-olivedrab-2 {
	background-color: rgb(125,156,42);
}

.bg-olivedrab-2:hover {
	background-color: rgb(115,146,32);
  color:white;
}

.border-olivedrab-2 {
	border: solid 1px rgb(125,156,42);
}

.bg-seagreen {
	background-color: rgb(69,156,72);
}

.bg-seagreen:hover {
	background-color: rgb(59,146,62);
  color:white;
}

.border-seagreen {
	border: solid 1px rgb(69,156,72);
}

.bg-orangered {
	background-color: rgb(241,93,0);
}

.bg-orangered:hover {
	background-color: rgb(221,73,0);
  color:white;
}

.border-orangered {
	border: solid 1px rgb(241,93,0);
}

.bg-darkorange {
  background-color: rgb(241,135,0);
}

.bg-darkorange:hover {
	background-color: rgb(221,115,0);
  color:white;
}

.border-darkorange {
	border: solid 1px rgb(241,135,0);
}

.col-12{
  display:flex;
  align-items:center;
}

@media all and (max-width: 960px) {
  .button-answer-line{
    width: 100%;
    min-height:3em;
    font-size:0.9em;
  }

  .col-12{
    display:flex;
    flex-direction:column-reverse;
    margin-bottom:10px;
  }

  .question-box{
    height:auto;
    margin: 40px 30px;
    padding: 20px 20px;
  }
}

@media all and (max-width: 576px) {
  .question-box {
    display: block;

    h5 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.8em;
    }
  }

  .img-innovation {
    div {
      margin: auto;
    }
  }

  .diagnosis-title p {
    font-size:2em;
  }

  #img-question {
    width: 8em;
    height:8em;
  }
}
</style>
