import axios from 'axios';
import { objectToFormData } from '@/util/object-to-formdata';

const BASE_URL = 'api';


const CLIENT = axios.create({
    baseURL: BASE_URL
});

export default {
    login: (password) => CLIENT.post('/login', password, {
        headers: { 'Content-Type': 'text/plain' }
    }),
    checkToken: (apiToken) => CLIENT.post('/checkToken', apiToken),
    resetPassword: (email) => CLIENT.post('/resetPassword', email),
    resetPasswordWithLink: (passwordReset) => CLIENT.put('/resetPasswordWithLink', passwordReset),
    keepAlive: () => CLIENT.get(`users/keepAlive`),
    logout: () => CLIENT.get(`users/logout`),
    getYoutubeLinks: () => CLIENT.get(`youtubeLinks`),

    getContactMail: () => CLIENT.get(`diagnosis/contactMail`),

    //Activities
    getActivities: () => CLIENT.get(`activity/all`),

    //Diagnosis
    createDiagnosisWithContact: (contact) => CLIENT.post('/diagnosis/create', contact),
    getDiagnosisByCode: (code) => CLIENT.get('/diagnosis/withCode/' + code),
    getDiagnosisQuestions: (code, forceCreation) => CLIENT.get('/diagnosis/questions/' + code + '/' + forceCreation),
    updateQuestionResult: (diagnosisQuestion) => CLIENT.post('/diagnosis/updateQuestionResult', diagnosisQuestion),
    getPositionnings: (diagnosis) => CLIENT.post('/diagnosis/axisResults', diagnosis),
    increaseStatus: (diagnosisId) => CLIENT.post('/diagnosis/increaseStatus', diagnosisId),
    downloadPdf: (graph, diagnosisCode) => CLIENT.post('/diagnosis/downloadPdf', {imageBase64: graph, diagnosisCode: diagnosisCode}),
    sendResultMail: (form) => CLIENT.post('/diagnosis/sendResultMail', form),

    //Dashboard
    getDashboardDatas: (filter) => CLIENT.post(`admin/dashboard`,filter),

    //Innovation
    getInnovations: () => CLIENT.get(`admin/innovations`),
    getInnovation: (id) => CLIENT.get(`admin/innovation/` + id),
    saveInnovation: (innovation) => CLIENT.post(`admin/save/innovation`, objectToFormData(innovation), {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),
    saveInnovations: (innovations) => CLIENT.post(`admin/save/innovations`, objectToFormData(innovations), {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),
    getInnovationPicture: (id) => CLIENT.get('diagnosis/innovation/picture/' + id),
    getInnovationsResults: (filter) => CLIENT.post('admin/innovationsResults', filter),
    getCompetencesResults: (filter) => CLIENT.post('admin/competencesResults', filter),

    //Axes
    getAxes: () => CLIENT.get(`axis/all`),
    setAxesDiagnosis: (diagnosisAxes) => CLIENT.post('/diagnosis/updateAxes', diagnosisAxes),

    //Maturities
    getMaturities: () => CLIENT.get(`innovation/maturities`),

    //Advices
    getAdvicesDiagnosis: (code) => CLIENT.get('diagnosis/advices/' + code),

    //exemple d'appel qui télécharge un fichier
    //exportExcel: (exportDTO) => CLIENT.post('/path', exportDTO,{
    //    responseType: 'blob'
    //}),

    //exemple d'appl qui upload un fichier
    //uploadRelocationCard: (relocationId, formData) => CLIENT.put(`/relocations/${relocationId}/uploadRelocationCard`, formData, {
    //    headers: { 'Content-Type': 'multipart/form-data' }
    //}),
}
