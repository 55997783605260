<template>
	<b-modal ref="create-diagnosis-modal" size="lg" :centered="!mobile" id="create-diagnosis-modal" cancel-disabled ok-disabled>
      <template #modal-header="{ close }">
        <p class="text-uppercase create-diagnosis-title blue">je démarre un nouveau diagnostic</p>
        <font-awesome-icon class="cursor-pointer blue m-left float-right" @click="close()" :icon="['far', 'times-circle']" size="2x"/>
      </template>
      <p class="text-uppercase" id="contact-modal-subtitle">Mes informations : </p>
      <b-form v-on:submit.prevent class="form-contact row">
        <p v-if="error" class="error col-md-12">
          {{error}}
        </p>
        <label for="input-lastname" class=" text-uppercase col-12 col-sm-4 label-contact-form blue  mandatory">Nom</label>
        <input class="col-11 col-sm-7 form-control" id="input-lastname" type="text" v-model="form.lastName">
        <span class="col-1 blue font-weight-bold d-none d-md-block">*</span>

        <label for="input-firstname" class="text-uppercase col-12 col-sm-4 label-contact-form blue  mandatory">Prénom</label>
        <input class="col-11 col-sm-7 form-control" id="input-firstname" type="text" v-model="form.firstName">
        <span class="col-1 blue font-weight-bold d-none d-md-block">*</span>

        <label for="input-email" class="text-uppercase col-12 col-sm-4 label-contact-form blue  mandatory">E-mail</label>
        <input class="col-11 col-sm-7 form-control" id="input-email" type="email" v-model="form.email">
        <span class="col-1 blue font-weight-bold d-none d-md-block">*</span>

        <label for="input-phone" class="text-uppercase col-12 col-sm-4 label-contact-form blue">Téléphone</label>
        <input class="col-11 col-sm-7 form-control" id="input-phone" type="text" v-model="form.phoneNumber">
        
        <label for="input-job" class="text-uppercase col-12 col-sm-4 label-contact-form blue">Fonction/Métier</label>
        <input class="col-11 col-sm-7 form-control" id="input-job" type="text" v-model="form.job">

        <label for="input-company" class="text-uppercase col-12 col-sm-4 label-contact-form blue mandatory">Société</label>
        <input class="col-11 col-sm-7 form-control" id="input-company" type="text" v-model="form.company">
        <span class="col-1 blue font-weight-bold d-none d-md-block">*</span>

        <label for="input-activities" class="text-uppercase col-12 col-sm-4 label-contact-form blue  mandatory">Activité principale</label>
        <b-dropdown class="col-11 col-sm-7" id="input-activities" :text="activitySelectText">
                    <b-dropdown-form class="w-200">

                        <div v-for="(activity,index) in activities" v-bind:key="index" class="dropdown-checkbox">
                            <b-form-checkbox
                                v-model="activity.selected">
                                {{ activity.name }} </b-form-checkbox>
                        </div>
                    </b-dropdown-form>
        </b-dropdown>
        <span class="col-1 blue font-weight-bold d-none d-md-block">*</span>

        <div class="col-4"></div>
        <p class="col-7 blue" id="asterisk-info">
          <span>* Champs obligatoires<span class="d-none d-lg-block"> (vos données restent confidentielles et pourront être utilisées uniquement par la FNTP et Constructys)</span></span>
        </p>

        <div id="contact-form-footer">
          <p class="blue diag-innov"><span class="font-weight-bold">DIAG</span>-INNOV-TP</p>
          <p class="text-footer">Vous allez recevoir par e-mail un code relatif à votre diagnostic.</p>
          <p class="text-footer">Conservez-le précieusement, il vous sera utile pour reprendre un diag en cours de réalisation ou consulter vos résultats.</p>
          
          <button v-if="!loading" id="contact-submit-button" class="bg-orangered text-uppercase white" @click="onSubmit">C'est parti</button>
          <div v-else class="small-spinner"><b-spinner></b-spinner></div>
        </div>
      </b-form>
      <template #modal-footer>
        <p></p>
      </template>
	</b-modal>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "../../api-requests"

export default {
  name: 'Home',
  data() {
    return {
      form: {
          lastName: '',
          firstName: '',
          email: '',
          phoneNumber: '',
          job:'',
          company:'',
          activities: []
		},
    error: null,
		ua: getUA,
		mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
		activities: [],
		activity: {},
    loading: false
    }
  },
  computed: {
    activitySelectText(){
      let selectedActivities = this.activities.filter(activity => activity.selected)
      if(selectedActivities.length == 1){
        return selectedActivities[0].name;
      } else if(selectedActivities.length > 1){
        return selectedActivities.length + " activités séléctionées"
      }
      return "Sélectionner";
    }
  },
  methods: {
    onSubmit() {

        this.form.activities = this.activities;

        if(this.checkForm(this.form) && !this.loading) {
          this.loading = true;
          API.createDiagnosisWithContact(this.form).then(response => {
            this.$store.commit("updateDiagnosis", response.data);
            this.$router.push({ name: 'quizz', params: { code: response.data.code } });
          });
        } else {
          this.loading = false;
        }

    },
    checkForm(form) {
      if(form.firstName == '' || form.lastName == '' || form.email == '' || form.company == '') {
        this.error = "Veuillez saisir tous les champs obligatoires";
        return false;
      }

      const re = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
      if(!re.test(form.email)) {
        this.error = "Adresse email invalide";
        return false;
      }

      let countSelected = 0;
      for(const activity of form.activities) {
        if(activity.selected) {
          countSelected++;
        }
      }
      if(countSelected == 0) {
        this.error = "Veuillez renseigner au moins une activité";
        return false;
      }

      this.error = null;
      return true;
    },
    show() {
      this.$refs['create-diagnosis-modal'].show();
    }
  },
  mounted() {
	API.getActivities()
		.then(response => {  
			this.activities = response.data;
		})  
		.catch(error => {
      console.log(error);
			this.error = "Le serveur est indisponible pour le moment, merci de réessayer dans quelques minutes";
		})  
		.finally(() => this.loading = false) 
  }
  
}
</script>

<style lang="scss">
    .create-diagnosis-title {
      margin: auto;
    }

    
    .dropdown-toggle::after{
      top: calc(50% - 3px);
      position: absolute;
      right: 17px;
    }


    .modal-header, .modal-footer {
      border: 0;
      padding-bottom:0;
    }

    .modal-body {
      padding-top: 0;
      
    }

    #contact-modal-subtitle {
      text-align: center;
    }

    #contact-submit-button {
      width:90%;
      margin-top: 20px;
      border:0;
      padding: 5px;
    }

    #contact-submit-button:hover, #contact-submit-button:focus, #contact-submit-button:active {
      background-color: rgb(221,115,0)!important;
      border:none!important;
      color: white;
	}

    #contact-form-footer {
      text-align:center;
      margin: auto;
      max-width:60%;
      font-size: 1rem;
    }

    #asterisk-info {
      font-size: 1rem;
      display: flex;
    }

    .diag-innov {
      font-size: 3rem;
      margin-bottom: 0;
    }

    #input-activities {
      padding:0;

      button{
        border-radius: 0;
      }
    }

    .text-footer {
      font-weight: 500;
      margin-bottom: 0;
      font-style: italic;
    }

    #create-diagnosis-modal {
      .dropdown-menu {
        background-color: #e4e4e4;
        border-radius: 0;
      }
    }
</style>

<style scoped lang="scss">
    .form-contact input {
      background-color: rgb(202,216,252);
      display: inline;
      border-radius:0;
    }

    .label-contact-form {
      text-align:right;
      font-size: 1.3rem;
    }

    .error{
      color: red;
      text-align: center;
      margin-bottom:1em;
    }

    .small-spinner{
      margin-top:15px;
    }

    .label-contact-form::after{
        content:" :";
      }

    @media all and (max-width: 767px) {
      .label-contact-form{
        text-align:center;
        margin-top: 10px;
        margin-bottom:0px;
      }
      .mandatory::after{
        content:" *";
        font-weight:bold;
      }

      .create-diagnosis-title {
        font-size: 0.9em;
      }

      #contact-modal-subtitle {
        font-size: 0.9em;
      }

      .form-contact{
        input {
          margin:auto;
          font-size:0.7em;
        }

        #input-activities {
          margin: auto;
        }

        .label-contact-form {
          font-size: 0.7em;
        }

        #contact-form-footer {
          max-width: 90%;
          .diag-innov {
            font-size: 2em;
          }

          .text-footer {
            font-size:0.8em;
          }
        }
      }
    }
    
</style>
