import { render, staticRenderFns } from "./QuizzHome.vue?vue&type=template&id=dcc3272c&scoped=true&"
import script from "./QuizzHome.vue?vue&type=script&lang=js&"
export * from "./QuizzHome.vue?vue&type=script&lang=js&"
import style1 from "./QuizzHome.vue?vue&type=style&index=1&id=dcc3272c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc3272c",
  null
  
)

export default component.exports