<template>
  <div id="progression-bar-block">
    <canvas id="progression-bar"></canvas>
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import {drawBar, drawCircle} from "../../util/chart-util";

var ProgressionBar = function(options){
    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
  
    this.draw = function(){
        var canvasActualHeight = this.canvas.height;
        var canvasActualWidth = this.canvas.width - this.options.padding * 2;
        let mobile = this.canvas.width < 600;

        drawBar(this.ctx, this.options.padding, (canvasActualHeight/2) - (canvasActualHeight/40), canvasActualWidth, (canvasActualHeight/12), "#e95e27");
        let nbQuestions = this.options.questions.length - 1;
        for(let i=0; i<nbQuestions; i++) {
          if(i == this.options.currentQuestion && this.options.answering) {
            let currentQuestionSize = mobile ? (canvasActualHeight/6) : (canvasActualHeight/4);
            drawCircle(this.ctx, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding, (canvasActualHeight/2), mobile ? (canvasActualHeight/8) : (canvasActualHeight/4), 0, 2 * Math.PI, "#e95e27", "#ffffff", 15);
            this.ctx.font = "800 " + currentQuestionSize +"px Arial";
            this.ctx.fillStyle="#e95e27";
            let sizeText = this.ctx.measureText(this.options.questions[i].number);
            this.ctx.fillText(this.options.questions[i].number, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding - (sizeText.width/2), (canvasActualHeight/2) + (canvasActualHeight/16));
          } else {
            let size = mobile ? (canvasActualHeight/12) : (canvasActualHeight/6);
            switch(this.options.questions[i].status) {
              case 1: 
                drawCircle(this.ctx, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding, (canvasActualHeight/2), size, 0, 2 * Math.PI, "#459c48", "#459c48", 15);
                break;
              case 2:
                drawCircle(this.ctx, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding, (canvasActualHeight/2), size, 0, 2 * Math.PI, "#d81a00", "#d81a00", 15);
                break;
              default:
                drawCircle(this.ctx, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding, (canvasActualHeight/2), size, 0, 2 * Math.PI, "#e95e27", "#e95e27", 15);
            }
            let textSize = mobile ? (canvasActualHeight/7) : (canvasActualHeight/5);
            this.ctx.font = "800 " + textSize +"px Arial";
            this.ctx.fillStyle="#ffffff";
            let sizeText = this.ctx.measureText(this.options.questions[i].number);
            this.ctx.fillText(this.options.questions[i].number, i*(canvasActualWidth/(nbQuestions-1)) + this.options.padding - (sizeText.width/2), (canvasActualHeight/2) + (canvasActualHeight/20));
          }
        }
    }
}

export default {
  name: 'QuizzProgression',
  props: ['questions', 'currentQuestion', 'answering'],
  components: {
  },
  watch: {
    answering: function() {
      this.resizeProgressionBarEvent();
    },
    currentQuestion: function() {
      this.resizeProgressionBarEvent();
    }
  },
  data() {
    return {
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),

    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    }
  },
  methods: {
    resizeProgressionBarEvent() {
			let canvasBlock = document.getElementById("progression-bar-block");
			let canvas = document.getElementById("progression-bar");
			canvas.width = canvasBlock.offsetWidth;
			canvas.height = canvasBlock.clientWidth >= 800 ? (canvasBlock.clientWidth/15) : 100;

			var progressionBar = new ProgressionBar(
				{
          canvas,
          padding:40,
          questions: this.questions,
          currentQuestion: this.currentQuestion,
          answering: this.answering
				}
			);
			progressionBar.draw();
    }
  },
  created() {
	window.addEventListener("resize", this.resizeProgressionBarEvent);
	},
	destroyed() {
	window.removeEventListener("resize", this.resizeProgressionBarEvent);
	},
  mounted() {
    this.resizeProgressionBarEvent();
  }
}
</script>

<style>
</style>

<style scoped>
  #progression-bar-block {
    width:80%;
    margin:auto;
  }
</style>
