const forceMobile = () => {
  return ["BAH2-W19"];
} 

const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const convertToHtml = (text) => {
  return text.replace(/\n/g, "<br>");
};

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const compareInnovations = (innov1, innov2) => {
  if(innov1.title != innov2.title) {
    return false;
  }

  if(innov1.complementaryInfo != innov2.complementaryInfo) {
    return false;
  }

  if(innov1.axis.code != innov2.axis.code) {
    return false;
  }

  if(innov1.maturity != innov2.maturity) {
    return false;
  }

  for(const [key,value] of Object.entries(innov1.activities)) {
    if(value.selected != innov2.activities[key].selected) {
      return false;
    }
  }

  return true;
}

export { isValidEmail, convertToHtml, forceMobile, capitalize, compareInnovations};
