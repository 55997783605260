import Vue from 'vue';
import Vuex from 'vuex';
import API from "../api-requests";

//required for file upload
//import { objectToFormData } from '@/util/object-to-formdata';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    diagnosis: {},
    apiToken: "",
    contactMail: ""
  },
  getters: {

  },
  mutations: {
    updateDiagnosis(state, diagnosis) {
      state.diagnosis = diagnosis;
    },
    onContactMailLoaded: (state, { data }) => {
      state.contactMail = data;
    },
  },
  actions: {
    updateDiagnosisWithAPI(context, code) {
      API.getDiagnosisByCode(code)
      .then(response => {  
        if(response.data.id == 0) {
          //TODO: FAIRE LE MESSAGE d'ERREUR DANS LA MODAL
        } else {
          context.commit("updateDiagnosis", response.data);
        }
      })  
      .catch(error => {  
        console.log(error);
        this.error = "Impossible de trouver de diagnostic avec ce code";
      })
    },
    getContactMail: async (context) => {
      try {
        context.commit('onContactMailLoaded', await API.getContactMail());
      } catch (e) {
        console.error(e);
      }
    },
  },
  modules: {
  }
});
