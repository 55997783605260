import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Quizz from "../views/Quizz.vue";
import Diagnosis from "../views/Diagnosis.vue";
import Administration from "../views/Administration.vue";
import AdminInnovation from "../views/AdminInnovation.vue";
import API from "@/api-requests";

Vue.use(VueRouter);

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Quizz/:code',
    name: 'quizz',
    component: Quizz,
  },
  {
    path: '/Diagnosis/:code',
    name: 'diagnosis',
    component: Diagnosis,
  },
  {
    path: '/Administration',
    name: 'administration',
    component: Administration,
  },
  {
    path: "/Administration/Innovation",
    name: "adminInnovation",
    component: AdminInnovation
  },
  {
    path: '*',
    redirect: '/Home'
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

const PROTECTED_VIEWS = ['administration'];

router.beforeEach((to, from, next) => {
  if(!PROTECTED_VIEWS.includes(to.name)) {
    next();
  } else {

    let apiToken = localStorage.getItem('apiToken');


    if(!apiToken) {
      console.log('not auth, redirect')
      next({ name: 'Home' });
    } 
    API.checkToken(apiToken).then(function(data){
      if(data.data === true){
        next();
      } else {
        next({ name: 'Home' });
      }
    });
  }

});

export default router
