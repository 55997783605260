<template>
    <div id="app">
        <Menu :class="noGutters"/>
        <router-view/>
    </div>
</template>

<script>
    import Menu from "@/components/common/Menu";

    export default {
        name: 'App',
          components: {
            Menu
        },
        data() {
          return {
              keepAliveInterval: undefined
          }
        },
        computed: {
            user(){
                return this.$store.state.user;
            },
            noGutters(){
                if (!this.mobile) return "";
                else return "no-gutters";
            },
        },
        created: function(){
            let $this = this;
            this.keepAliveInterval = setInterval(function(){
                if($this.user){
                    $this.$store.dispatch('keepAlive');
                }
            },144000)
        }
    }
</script>


<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Advent+Pro');
    @import url('https://fonts.googleapis.com/css?family=Raleway');

    $constructys-color-primary: rgb(112,112,112);
    $constructys-color-secondary: rgb(228,228,228);
    $theme-colors: (
            "primary": $constructys-color-primary,
            "secondary": $constructys-color-secondary,
    );

    @import '~bootstrap/scss/bootstrap';

    @import '../node_modules/bootstrap-vue/src/index.scss';


    .title {
      padding: 0;
      margin: 20px 38px;
      font-size: 50px;
      font-weight: 200;
      border-bottom: solid 6px rgb(241,135,0);
    }

    .spinner-div{
        text-align: center;
    }
</style>