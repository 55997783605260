<template>
  <div>
    <div id="block-question-canvas">
      <canvas id="question-canvas">
          <img @load="handleLoad" id="img-phone" src="../../assets/img_phone.png" width="150" height="150">
          <img @load="handleLoad" id="img-auto" src="../../assets/img_auto.svg" width="150" height="150">
          <img @load="handleLoad" id="img-bim" src="../../assets/img_bim.png" width="130" height="130">
          <img @load="handleLoad" id="img-eurocode" src="../../assets/img_eurocode.png" width="150" height="150">
          <img @load="handleLoad" id="img-imprim" src="../../assets/img_imprim.png" width="150" height="150">
      </canvas>
    </div>
    <div v-if="answering && !done" class="col-11 col-lg-6 bg-steelblue" id="title-question-block">
      <div class="content-diagnosis-question">
        <div id="question-mark">?</div>
        <span>{{questions[currentQuestion].title}}</span>
      </div>
      
    </div>
    <b-button v-if="!answering && currentQuestion!=questions.length-1" @click="nextQuestion()" class="col-9 col-lg-6" :class="questions[currentQuestion].status == 1 ? 'bg-forestgreen' : 'bg-red-2'" id="title-question-block">
      <div class="button-result-question">
        <div class="check-or-times">
          <font-awesome-icon class="m-left vertical-center-2" :class="questions[currentQuestion].status == 1 ? 'color-forestgreen' : 'color-red-2'" :icon="['fas', questions[currentQuestion].status == 1 ? 'check' : 'times']" size="2x"/>
        </div>
        <span v-if="currentQuestion + 2 != questions.length" class="text-uppercase">
          {{questions[currentQuestion].status == 1 ? "Bravo ! " : ""}} Question suivante
        </span>
        <span v-else class="text-uppercase">
          Voir le résultat du quiz !
        </span>
        <font-awesome-icon class="m-left vertical-center-2" :icon="['fas','play']" size="2x"/>
      </div>
    </b-button>
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import { QuizzCanvas } from "../../util/chart-util";

export default {
  name: 'QuizzQuestion',
  props: ['questions', 'currentQuestion', 'answering'],
  components: {
  },
  watch: {
    answering: function() {
      this.resizeQuizzCanvasEvent();
    },
    currentQuestion: function() {
      this.resizeQuizzCanvasEvent();
    },
  },
  data() {
    return {
		ua: getUA,
    mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
    counter: 0,
    len: 0,
    done: false,
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    }
  },
  methods: {
    resizeQuizzCanvasEvent() {
      var vm = this;
			let canvasBlock = document.getElementById("block-question-canvas");
      let canvas = document.getElementById("question-canvas");
      canvas.style.cursor = 'default';
      canvas.width = canvasBlock.clientWidth >= (Math.max(this.currentQuestion + 1, 4)) * 200 ? canvasBlock.clientWidth - 50 : (Math.max(this.currentQuestion + 1, 4 )) * 200;
      canvas.height = canvas.width / (Math.max(this.currentQuestion + 1, 4));

			var quizzCanvas = new QuizzCanvas(
				{
          canvas,
          questions: this.questions,
          currentQuestion: this.currentQuestion,
          answering: this.answering,
          document: document,
          done: this.done
				}
			);
      var questionPosition = quizzCanvas.draw();
      var offsetX=canvas.offsetLeft;
      var offsetY=canvas.offsetTop;

      var isPointerInsideAnswer = function(pointerX, pointerY, position, width, height) {
      return(pointerX>=position[0]
                  && pointerX<=position[0]+width
                  && pointerY>=position[1]
                  && pointerY<=position[1]+height);
      }

      function reOffset(){
          var BB=canvas.getBoundingClientRect();
          offsetX=BB.left;
          offsetY=BB.top;        
      }
      
      canvas.onmousemove = function(e) {
        reOffset();
        let x = e.clientX - offsetX;
        let y = e.clientY - offsetY;

        for(const position of questionPosition.positions) {
          if(vm.isPointerInsideAnswer(x, y, position, questionPosition.width, questionPosition.width)) {
            canvas.style.cursor = "pointer";
            break;
          } else {
            canvas.style.cursor = "default";
          }
        }
      }

      canvas.onclick = function(e) {
        reOffset();
        let x = e.clientX - offsetX;
        let y = e.clientY - offsetY;

        for(let i=0; i < questionPosition.positions.length; i++) {
          if(isPointerInsideAnswer(x, y, questionPosition.positions[i], questionPosition.width, questionPosition.width)) {
            vm.checkAnswerQuestion(i);
            break;
          }
        }
      }
    },
    handleLoad() {
        this.counter++;
        if ( this.counter === this.len ) {
            this.resizeQuizzCanvasEvent();
        }
    },
    checkAnswerQuestion(position) {
      let question = this.questions[this.currentQuestion];
      if(question.answer.position == position) {
        question.status = 1;
      } else {
        question.status = 2;
      }
      this.$emit("updateAnsweringEvent", false);
    },
    isPointerInsideAnswer(pointerX, pointerY, position, width, height) {
      return(pointerX>=position[0]
                  && pointerX<=position[0]+width
                  && pointerY>=position[1]
                  && pointerY<=position[1]+height);
      },
    nextQuestion() {
      this.$emit("nextQuestion");
      if(this.currentQuestion != this.questions.length - 2) {
        this.$emit("updateAnsweringEvent", true);
      } else {
        this.done = true;
        this.$emit("quizzDone");
      }
    }
  },
  created() {
    window.addEventListener("resize", this.resizeQuizzCanvasEvent);
	},
	destroyed() {
    window.removeEventListener("resize", this.resizeQuizzCanvasEvent);
	},
  mounted() {
    var imgs = document.images;
    this.len = imgs.length;
    this.counter = 0;
  },
}
</script>

<style lang="scss">
    .content-diagnosis-question {
      display:flex;
      align-items: center;

        span{
          margin-left:60px;
        }
    }

    @media all and (max-width: 960px) {
      .content-diagnosis-question span {
        margin-left:10px;
      }
    }
</style>

<style scoped lang="scss">
  #block-question-canvas {
    margin-left:5%;
    margin-right:5%;
    overflow: scroll;

    background:     
      linear-gradient(90deg,#fff 33%,rgba(255,255,255,0)),
      linear-gradient(90deg,rgba(255,255,255,0),#fff 66%) 0 100%,
      radial-gradient(farthest-side at 0 50%,rgba(0,0,0,.4),transparent),
      radial-gradient(farthest-side at 100% 50%,rgba(0,0,0,.4),transparent) 0 100%;
    /* Shadows */
    /* Shadow covers */
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
  }

  #block-question-canvas::-webkit-scrollbar {
    width: 0 !important
  }

  #title-question-block{
    display: block;
    color: white;
    padding: 10px;
    margin: auto;
    border-radius:0;
    margin-bottom: 1%;
    box-shadow: 1px 1px 6px #707070;
    border: none;

    #question-mark{
       padding: 0.1em 0.4em;
      width: 1.3em;
      height: 1.3em;
      font-size: 4em;
      font-weight:900;
      border-radius: 50%;
      background-color:white;
      color: $steelblue;
      text-align:center;
      line-height: 1;
    }

    span{
      font-size: 1.5em;
    }
  }

  .color-forestgreen {
    color: rgb(59,132,38);
  }

  .color-red-2 {
    color: rgb(206,16,0);
  }

  .bg-forestgreen:hover, .bg-forestgreen-2:active, .bg-forestgreen-2:focus {
    background-color: rgb(49,122,28)!important;
  }

  .bg-red-2:hover, .bg-red-2:active, .bg-red-2:focus {
    background-color: rgb(206,16,0)!important;
  }

  .button-result-question {
    .check-or-times {
      font-size: 3vh;
      padding:2vh;
      background-color: white;
      border-radius: 50%;
      width: 10vh;
      height: 10vh;
    }

    span {
      width: 70%;
    }

    svg {
      text-align:right;
    }
    display: flex;
    align-items: center;
    justify-content:space-around;
  }
</style>
