import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import App from './App.vue';

import { sync } from 'vuex-router-sync';
import router from './router';
import store from './store';

import './faIconsProvider';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { convertToHtml } from "./util/util";
import '@/css/theme.scss';

sync(store, router);

library.add(faTimesCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

const moment = require('moment');
require('moment/locale/fr');

Vue.use(require('vue-moment'), { moment });

Vue.filter('textToHtml', text => convertToHtml(text));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
