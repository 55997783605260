function drawLine(ctx, startX, startY, endX, endY,color){
    ctx.save();
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(startX,startY);
    ctx.lineTo(endX,endY);
    ctx.stroke();
    ctx.restore();
}

function drawArc(ctx, centerX, centerY, radius, startAngle, endAngle){
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.stroke();
}

function drawCircle(ctx, centerX, centerY, radius, startAngle, endAngle, lineColor, bgColor, lineSize){
    ctx.beginPath();
    ctx.fillStyle=bgColor;
    ctx.strokeStyle=lineColor;
    ctx.lineWidth = lineSize;
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.stroke();
    ctx.fill();
}

function drawPieSlice(ctx,centerX, centerY, radius, startAngle, endAngle, color ){
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(centerX,centerY);
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.closePath();
    ctx.fill();
}

function drawBar(ctx, upperLeftCornerX, upperLeftCornerY, width, height,color){
    ctx.save();
    ctx.fillStyle=color;
    ctx.fillRect(upperLeftCornerX,upperLeftCornerY,width,height);
    ctx.restore();
}

function drawRoundedBar(ctx, x, y, width, height, radius, color) {
    ctx.save();
  ctx.beginPath();
  ctx.fillStyle=color;
  ctx.moveTo(x + radius, y);
  // top right corner
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  // bottom right	corner
  ctx.lineTo(x + width, y + height);
  // bottom left corner
  ctx.lineTo(x, y + height);
  // top left	
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
    ctx.restore();
}

function drawRoundedRect(ctx, x, y, width, height, radius, lineColor, bgColor, lineSize, dottedLine, lineDash) {
    ctx.save();
  ctx.beginPath();
    ctx.fillStyle=bgColor;
    ctx.strokeStyle=lineColor;
    ctx.lineWidth = lineSize;

    if(dottedLine) {
        ctx.setLineDash(lineDash);
    }
  ctx.moveTo(x + radius, y);
  // top right corner
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  // bottom right	corner
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  // bottom left corner
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  // top left	
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
          ctx.shadowOffsetX = 5;
    ctx.shadowOffsetY = 5;
    ctx.shadowColor = '#dedede';
    ctx.shadowBlur = 5;
    ctx.fill();
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowColor = '#dedede';
    ctx.shadowBlur = 0;
  ctx.stroke();

    ctx.restore();
}

function drawTriangle(ctx, baseX1, baseY1, baseX2, baseY2, h, direction, bgColor, lineColor, lineSize) {
    ctx.fillStyle=bgColor;
    ctx.strokeStyle=lineColor;
    ctx.lineWidth = lineSize;
    ctx.beginPath();
    ctx.moveTo(baseX1, baseY1);
    ctx.lineTo(baseX2, baseY2);
    switch(direction) {
        case 'l': ctx.lineTo(baseX2 - h, (baseY1 + baseY2)/2 );break;
        case 'r': ctx.lineTo(baseX2 + h, (baseY1 + baseY2)/2 );break;
        case 'u': ctx.lineTo((baseX2 + baseX1) /2, baseY1 - h );break;
        case 'd': ctx.lineTo((baseX2 + baseX1) /2, baseY1 + h );break;
        default:
    }
    ctx.stroke();
    ctx.fill();
}

var Barchart = function(options){
    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.colors = options.colors;
  
    this.draw = function(){
        var maxValue = this.options.gridMax;
        let startGraphY = this.canvas.height * 0.05;
        var canvasActualHeight = this.canvas.height - startGraphY - this.options.padding * 2;
        var canvasActualWidth = this.canvas.width - this.options.padding * 2;

        //drawing legend
        drawBar(this.ctx, canvasActualWidth/10, 0, canvasActualWidth, this.canvas.height * 0.08, this.options.backgroundColor);
        this.ctx.fillStyle = '#373737';
        this.ctx.font = "600 0.7em Raleway";
        let sizes = [];
        let sizeTotal = 0;
        for(const legend of this.options.legends) {
            let sizeText = this.ctx.measureText(legend).width;
            sizes.push(sizeText);
            sizeTotal += sizeText;
        }
        let textZeroValue = "Non concerné".toUpperCase();
        let sizeNullText = this.ctx.measureText(textZeroValue).width;
        sizes.push(sizeNullText)
        sizeTotal+=sizeNullText;
        
        let currentLegendX = canvasActualWidth/10;
        for(let i=0; i <= this.options.legends.length; i++) {
            let ratioLegend = sizes[i] / sizeTotal;
            if(i == this.options.legends.length) {
                drawBar(this.ctx, currentLegendX, 0, 10, this.canvas.height * 0.08, '#e4e4e4');
                this.ctx.fillText(textZeroValue, currentLegendX + canvasActualWidth/30, this.canvas.height * 0.05)
            } else {
                drawBar(this.ctx, currentLegendX, 0, 10, this.canvas.height * 0.08, this.options.colors[i]);
                this.ctx.fillText(this.options.legends[i], currentLegendX + canvasActualWidth/30, this.canvas.height * 0.05)
            }
            currentLegendX = currentLegendX + (canvasActualWidth*ratioLegend);
        }
 
        //drawing background
        drawBar(this.ctx, canvasActualWidth/10, this.options.padding + startGraphY, canvasActualWidth, canvasActualHeight-(canvasActualHeight/10), this.options.backgroundColor);

        //write labels
        let nbLabels = this.options.labels.length;
        let ecartLabel = (canvasActualWidth)/(nbLabels*2);
        let currentLabel = 0;
        for(const label of this.options.labels) {
            this.ctx.fillStyle = '#373737';
            this.ctx.font = "600 0.7em Raleway";
            let sizeLabel = this.ctx.measureText(label);
            let currentPos = (canvasActualWidth)/nbLabels*currentLabel + (canvasActualWidth/10) - sizeLabel.width/2;
            this.ctx.fillText(label, currentPos+ecartLabel, canvasActualHeight - (canvasActualHeight/10) + this.options.padding + (canvasActualHeight/20) + startGraphY)
            currentLabel++;
        }

        //drawing the grid lines
        var gridValue = 1;
        while (gridValue <= maxValue){
            var gridY = (canvasActualHeight-(canvasActualHeight/10)) * (1 - gridValue/maxValue) + this.options.padding + startGraphY;
            if(gridValue != maxValue) {
                drawLine(
                    this.ctx,
                    canvasActualWidth/10,
                    gridY,
                    canvasActualWidth+canvasActualWidth/10,
                    gridY,
                    this.options.gridColor
                );
            }

            //writing grid markers
            this.ctx.save();
            this.ctx.fillStyle = this.options.gridColor;
            this.ctx.font = (15 + (((this.canvas.height + startGraphY) * this.canvas.width)/200000)) +"px Raleway";
            this.ctx.fillText(gridValue, (canvasActualWidth/10) - this.options.padding ,gridY + (canvasActualHeight/50));
            this.ctx.restore();
 
            gridValue+=this.options.gridScale;
        }
  
        //drawing the bars
        let nbGroup = this.options.data.length;
        let availableSizeForGroup = (canvasActualWidth)/nbGroup;
        let currentX = canvasActualWidth/10;
        for(const group of this.options.data) {
            let groupSize = group.length;
            let barSize = availableSizeForGroup * (100 / ((2*groupSize)+1) / 100);
            currentX += barSize;
            let index = 0;
            for(const data of group) {
                let barHeight = ((canvasActualHeight-(canvasActualHeight/10))/this.options.gridMax) * data;
                if(data == null) {
                    barHeight = (canvasActualHeight-(canvasActualHeight/10)) * 0.02;
                    drawBar(
                        this.ctx,
                        currentX,
                        canvasActualHeight - (canvasActualHeight/10) + this.options.padding - barHeight + startGraphY,
                        barSize,
                        barHeight,
                        '#e4e4e4'
                    );
                } else {
                    if(data < 0.5) {
                        drawBar(
                            this.ctx,
                            currentX,
                            canvasActualHeight - (canvasActualHeight/10) + this.options.padding - barHeight + startGraphY,
                            barSize,
                            barHeight,
                            this.colors[index%this.colors.length]
                        );
                    } else {
                        drawRoundedBar(
                            this.ctx,
                            currentX,
                            canvasActualHeight - (canvasActualHeight/10) + this.options.padding - barHeight + startGraphY,
                            barSize,
                            barHeight,
                            barSize/2,
                            this.colors[index%this.colors.length]
                        );
                    }
                    this.ctx.fillStyle = '#000000';
                    this.ctx.font = (12 + (((this.canvas.height + startGraphY) * this.canvas.width)/200000)) +"px Raleway";
                    let textSize = this.ctx.measureText(Math.round(data * 10) / 10);
                    this.ctx.fillText(Math.round(data * 10) /10, currentX + (barSize/2) - (textSize.width/2), canvasActualHeight - (canvasActualHeight/10) + this.options.padding - barHeight + startGraphY - 10)
                }
                index++;
                currentX += barSize*2;
            }
        }
    }
}

var QuizzCanvas = function(options){
    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
  
    this.draw = function(){
        var ctx = this.ctx;
        var canvasActualHeight = this.canvas.height;
        var canvasActualWidth = this.canvas.width - (this.canvas.width/20);
        var lineWidth = this.canvas.width;
        var answerSquarePosition = [];
        var question = this.options.questions[this.options.currentQuestion];
        var nbDates = question.dates.length;
        //On définit la taille des éléments en fonction du nombre d'éléments qu'on va afficher
        var widthElements = canvasActualWidth/(((nbDates*2) + 1) * 1.5);
        if(widthElements > canvasActualHeight / 1.3) {
            widthElements = canvasActualHeight / 1.3;
        }
        var widthElementsImg = canvasActualWidth/(((nbDates*2) + 1)* 1.1);
        var widthElementsImgAnswer = canvasActualWidth/(((nbDates*2) + 1)*0.9);
        if(this.options.done) {
            widthElements = canvasActualWidth/(((nbDates) + 1) * 1.5);
            widthElementsImg = canvasActualWidth/(((nbDates) + 1));
        }
        if(widthElementsImgAnswer > canvasActualHeight) {
            widthElementsImgAnswer = canvasActualHeight;
        }
        if(widthElementsImg > canvasActualHeight / 1.5) {
            widthElementsImg = canvasActualHeight / 1.5;
        }
        if(widthElements > 180) {
            widthElements = 180;
        } else if(widthElements < 100) {
            widthElements = 100;
        }
        var blockSize = canvasActualWidth/((nbDates*2) + 1);
        
        this.ctx.lineWidth = canvasActualHeight/20;
        this.ctx.setLineDash([2, 2]);
        drawLine(this.ctx, 0 , canvasActualHeight*0.6, lineWidth - (lineWidth/100), canvasActualHeight*0.6,"#e95e27");
        drawTriangle(this.ctx, lineWidth - (lineWidth/100), (canvasActualHeight*0.6) - (canvasActualHeight/40), lineWidth - (lineWidth/100), (canvasActualHeight*0.6) + (canvasActualHeight/40), (lineWidth/100), 'r', "#e95e27", "#e95e27", 1);
        drawTriangle(this.ctx, 0, (canvasActualHeight*0.6) - (canvasActualHeight/40), 0, (canvasActualHeight*0.6) + (canvasActualHeight/40), (lineWidth/100), 'r', "#ffffff", "#ffffff", 1);

        
        let currentX = 0;
        for(let i = 0; i < question.dates.length; i++) {
            const date = question.dates[i];
            if(this.options.answering) {
                answerSquarePosition.push([currentX + ((blockSize/2) - (widthElements/3)), canvasActualHeight*0.6 - (widthElements/3)])
                drawRoundedRect(this.ctx, currentX + ((blockSize/2) - (widthElements/3)), canvasActualHeight*0.6 - (widthElements/3), widthElements/1.5, widthElements/1.5, 10, "#338FB8", "#ffffff", 3, true, [4,4]);
                this.ctx.fillStyle = "#338FB8";
                this.ctx.font = widthElements/2 +"px Raleway";
                let sizeText = this.ctx.measureText("?");
                this.ctx.fillText("?", currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.6 + widthElements/6);
            } else {
                if(!this.options.done && question.answer.position == i) {
                    this.ctx.setLineDash([]);
                    drawCircle(ctx, currentX + (blockSize/2), canvasActualHeight*0.5, widthElementsImgAnswer/4, 0, 2 * Math.PI, "#e95e27", "#ffffff", 15);
                    let img = this.options.document.getElementById(question.answer.image.name);
                    if(img != null) {
                        let imgSize = widthElementsImgAnswer / 2 * question.answer.image.ratio;
                        let imgMove = imgSize / 2;
                        this.ctx.drawImage(img, currentX + (blockSize/2) - imgMove, canvasActualHeight*0.5 - imgMove, imgSize, imgSize);
                    }
                    this.ctx.font = "600 " + canvasActualHeight/10 +"px Raleway";
                    this.ctx.fillStyle = "#e95e27";
                    let sizeText = this.ctx.measureText(question.answer.year);
                    this.ctx.fillText(question.answer.year, currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.5 + widthElementsImgAnswer/4 + canvasActualHeight/8);
                }
            }
            currentX += blockSize;

            this.ctx.setLineDash([]);


            let img = this.options.document.getElementById(date.image.name);
            if(img != null && question.number!="1") {
                drawTriangle(ctx, currentX + (blockSize/2) - widthElementsImg/8 - 5, (canvasActualHeight*0.6) - widthElementsImg/4, currentX + (blockSize/2) + widthElementsImg/8 + 5, (canvasActualHeight*0.6) - widthElementsImg/4, widthElementsImg/3 + 5, 'd', "#ffffff", "#ffffff", 1);
                drawTriangle(ctx, currentX + (blockSize/2) - widthElementsImg/8, (canvasActualHeight*0.6) - widthElementsImg/4, currentX + (blockSize/2) + widthElementsImg/8, (canvasActualHeight*0.6) - widthElementsImg/4, widthElementsImg/3, 'd', "#e95e27", "#e95e27", 1);
                drawCircle(ctx, currentX + (blockSize/2), (canvasActualHeight*0.6) - widthElementsImg/2, widthElementsImg/4, 0, 2 * Math.PI, "#e95e27", "#ffffff", 15);
                let imgSize = widthElementsImg / 2 * date.image.ratio;
                let imgMove = imgSize / 2;
                this.ctx.drawImage(img, currentX + (blockSize/2) - imgMove, (canvasActualHeight*0.6) - widthElementsImg/2 - imgMove, imgSize, imgSize);
                this.ctx.font = "600 " + canvasActualHeight/10 +"px Raleway";
                this.ctx.fillStyle = "#e95e27";
                let sizeText = this.ctx.measureText(date.year);
                this.ctx.fillText(date.year, currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.6 + widthElementsImg/3);
            } else {
                drawTriangle(ctx, currentX + (blockSize/2) - widthElements/8 - 5, (canvasActualHeight*0.6) - widthElements/4, currentX + (blockSize/2) + widthElements/8 + 5, (canvasActualHeight*0.6) - widthElements/4, widthElements/3 + 5, 'd', "#ffffff", "#ffffff", 1);
                drawTriangle(ctx, currentX + (blockSize/2) - widthElements/8, (canvasActualHeight*0.6) - widthElements/4, currentX + (blockSize/2) + widthElements/8, (canvasActualHeight*0.6) - widthElements/4, widthElements/3, 'd', "#e95e27", "#e95e27", 1);
                this.ctx.font = "600 " + canvasActualHeight/10 +"px Raleway";
                this.ctx.fillStyle = "#e95e27";
                let sizeText = this.ctx.measureText(date.year);
                this.ctx.fillText(date.year, currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.6 - widthElements/3 + 10);


                if(date.text != null) {
                    this.ctx.font = "300 " + canvasActualHeight/15 +"px Raleway";
                     sizeText = this.ctx.measureText(date.text);
                    this.ctx.fillText(date.text, currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.6 + canvasActualHeight/5);
                }
            }
            currentX += blockSize;
        }

        if(this.options.answering) {
                answerSquarePosition.push([currentX + ((blockSize/2) - (widthElements/3)), canvasActualHeight*0.6 - (widthElements/3)])
                drawRoundedRect(this.ctx, currentX + ((blockSize/2) - (widthElements/3)), canvasActualHeight*0.6 - (widthElements/3), widthElements/1.5, widthElements/1.5, 10, "#338FB8", "#ffffff", 3, true, [4,4]);
                this.ctx.fillStyle = "#338FB8";
                this.ctx.font = widthElements/2 +"px Raleway";
                let sizeText = this.ctx.measureText("?")
                this.ctx.fillText("?", currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.6 + widthElements/6);
            } else if(!this.options.done && question.answer.position == question.dates.length) {
                this.ctx.setLineDash([]);
                drawCircle(ctx, currentX + (blockSize/2), canvasActualHeight*0.5, widthElementsImg/2, 0, 2 * Math.PI, "#e95e27", "#ffffff", 15);
                let img = this.options.document.getElementById(question.answer.image.name);
                if(img != null) {
                    let imgSize = widthElementsImg * question.answer.image.ratio;
                    let imgMove = imgSize / 2;
                    this.ctx.drawImage(img, currentX + (blockSize/2) - imgMove, canvasActualHeight*0.5 - imgMove, imgSize, imgSize);
                }
                this.ctx.font = "600 " + canvasActualHeight/10 +"px Raleway";
                this.ctx.fillStyle = "#e95e27";
                let sizeText = this.ctx.measureText(question.answer.year);
                this.ctx.fillText(question.answer.year, currentX + (blockSize/2) - sizeText.width/2, canvasActualHeight*0.5 + widthElementsImg/2 + canvasActualHeight/8);    
            }
        
        return {width: widthElements/1.5, positions: answerSquarePosition};
    }
}

export {drawLine, drawArc, drawPieSlice, drawBar, drawRoundedBar, Barchart, drawCircle, drawTriangle, drawRoundedRect, QuizzCanvas};