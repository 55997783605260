<template>
        <b-navbar class="constructys-navbar menu-height" toggleable="md" type="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <font-awesome-icon title="Accueil" class="cursor-pointer m-left vertical-center-2 home-button darkgray" @click="toHome()" :icon="['fas', 'home']" size="3x"/>

            <b-collapse id="nav-text-collapse" is-nav>
                <b-navbar-nav class="ml-auto align-items-end">
                    <b-nav-item target="_blank" href="https://www.fntp.fr/" class="cursor-pointer to-constructys">
                        <div>
                            <font-awesome-icon class="m-left vertical-center-2 darkgray" :icon="['fa', 'arrow-left']" size="lg"/>
                        </div>
                        <div class="font-weight-bold darkgray">FNTP.FR</div>
                    </b-nav-item>
                    <b-nav-item class="bg-gradient-red menu-height admin" @click="toggleAdminPasswordPopup(false)">
                        <div>
                            <font-awesome-icon class="m-left vertical-center-2 darkgray" :icon="['fa', 'lock']" size="2x"/>
                        </div>
                        <b-nav-text class="text-uppercase admin-link darkgray">Administration</b-nav-text>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <div v-if="togglePasswordInput" class="admin-password-popup" :class="{'custom-height':badCredentialError}">
                <font-awesome-icon v-if="!hidePassword" @click="toggleShowPassword" class="m-left vertical-center-2 show-password" :icon="['fa', 'eye']" />
                <font-awesome-icon v-else @click="toggleShowPassword" class="m-left vertical-center-2 show-password" :icon="['fa', 'eye-slash']" />
                <label class="input-label-password" for="input-password">Mot de passe : </label>
                <div class="input-group-password-container">
                    <form v-on:submit.prevent>
                    <b-input-group class="input-group-password">
                        <input class="bg-darkorange form-control" id="input-password" v-model="password" :type="passwordInputType" v-on:keyup.enter="checkAdminPageAccess" />
                            <b-input-group-append>
                                <b-button class="bg-chocolate" @click="checkAdminPageAccess">
                                <font-awesome-icon class="m-left vertical-center-2 password-icon white" :icon="['fas', 'play-circle']" size="2x"/>
                                </b-button>
                            </b-input-group-append>
                    </b-input-group>
                    </form>
                </div>
                <div v-if="badCredentialError" class="badCredentialError">Mot de passe erroné</div>
            </div>
        </b-navbar>
</template>

<script>
    import { getUA, isMobile } from 'mobile-device-detect';
    import { forceMobile } from "@/util/util";
    import API from "@/api-requests";

    export default {
        name: "Menu",
        components: {},
        data() {
            return {
                ua: getUA,
                mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
                password: "",
                hidePassword:true,
                togglePasswordInput:false,
                badCredentialError: false
            }
        },
        methods: {
            toggleShowPassword(){
                this.hidePassword = !this.hidePassword;
            },
            toHome(){
                this.$router.push('/Home');
            },
            toggleAdminPasswordPopup() {
                if(this.$route.name != "administration"){
                    this.badCredentialError = false;
                    this.togglePasswordInput = !this.togglePasswordInput;
                }
                
            },
            checkAdminPageAccess(){
                let $this = this;
                if(this.password.length > 0){
                    API.login(this.password).then(function(data){
                        if(data.data != "401"){
                            $this.badCredentialError = false;
                            $this.$store.state.apiToken = data.data;
                            localStorage.setItem('apiToken',data.data)
                            $this.toggleAdminPasswordPopup();
                            $this.password = "";
                            $this.$router.push({ name: 'administration'});
                        } else {
                            $this.badCredentialError = true;
                        }
                    })
                } else {
                    $this.badCredentialError = true;
                }
            }
        },
        computed: {
            passwordInputType(){
                if(this.hidePassword){
                    return "password";
                } else {
                    return "text";
                }
            },
            user() {
                return this.$store.state.user;
            },
            menuHeight(){
                if (!this.mobile) return "menu-height";
                else return "";
            },
        }
    }
</script>

<style lang="scss">
    .cursor-pointer {
        cursor: pointer;
    }

    .white{
        color:white;
    }

    .home-button {
        margin:5px;
    }

    .to-constructys {
        margin-right:30px;
        color: rgb(87,94,98);
        margin-bottom: 10px;

        a {
            display: flex;

            div {
                margin-right: 10px;
            }
        }
    }

    .admin{
        height: 58px;
        padding-left:10px;
        padding-right:10px;
        border-left: solid 1px $darkgray;
        a {
            display: flex;
            margin-top: 3px;

            div {
                align-self: center;
                margin-right: 15px;
            }
        }
    }
</style>

<style scoped lang="scss">
    @import '../../css/theme.css';

    .badCredentialError{
        font-weight: bold;
        text-align: center;
        color: #ad0000;
        margin-top: -33px;
    }

    .constructys-navbar {
        padding:0;
    }
    
    .admin-link{
        font-size:0.8em;
        font-weight: 700;
        margin-top: 5px;
    }

    .admin-password-popup{
        position:absolute;
        z-index: 1;
        top:58px;
        right:0px;
        background-color:rgb(241,135,0);
        height: 58px;
        line-height:58px;
        padding-left:15px;
        color:white;
        font-size:1.1em;
        box-shadow: -2px 2px 5px #707070;

        label{
            width: 33%;
            font-size: 0.8em;
        }

        .show-password{
            color:#707070;
            position:absolute;
            right:68px;
            top:17px;
            z-index:999;
            font-size:1.3em;
            cursor:pointer;
            background-color:white;
            padding-left:2px;
        }
    }

    .custom-height{
        height:78px;
    }
    
.input-label-password{
    display:inline-block;
    user-select: none;
}

.input-group-password-container{
    display:inline-block;
    width: 66%;

}

.input-group-password{
    flex-wrap: unset;

    .bg-chocolate:focus, .bg-chocolate:hover, .bg-chocolate:active{
        background-color: rgb(209, 65, 8)!important;
    }

    .password-icon{
        height:32px;
        padding-bottom:4px;
        margin-top:-1px;
    }

    input {
      color: rgb(70, 70, 70);
      background-color:white;
      font-size:1em;
      border: 0;
      border-radius: 6px 0px 0px 6px;
      letter-spacing: 0px;
      height:37px;
      width:250px;
    }

    input:focus, input:active, input:hover {
        background-color:white!important;
        border:none!important;
    }

    input::placeholder {
      color: white;
      font-weight: 900;
    }

    input:focus {
      background-color: rgb(233,94,39);
    }

    .input-group-append{
        height:37px;
      button {
        border: 0;
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
    
</style>
