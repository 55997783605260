<template>
  <div>
    <div class="bg-midnightblue block-title">
      <h1>BIENVENUE SUR <span class="font-weight-bold">DIAG</span>-INNOV-TP</h1>
      
    </div>

    <div class="bg-whitesmoke home-block">
      <div class="home-block-title">
        <div>
          <font-awesome-icon class="m-left vertical-center-2 blue" :icon="['fas', 'compass']" size="3x"/>
        </div>
        <h2 class="bright text-uppercase margin-left-10">Faites le point avec le DIAG-Innov-TP et découvrez votre niveau de maturité face à l'innovation</h2>
      </div>
      <div class="home-block-body">
        <p>Diag-Innov-TP est un auto-diagnostic conçu pour vous permettre de faire le point sur le niveau d'appropriation de votre entreprise sur les innovations du secteur des travaux publics réparties autour de 4 thématiques.</p>
        <p>En une dizaine de minutes, vous visualisez la situation globale de votre entreprise, vous confirmez vos forces et identifiez les sujets sur lesquels vous avez des enjeux de progression. Si vous le souhaitez, vous pouvez être mis en relation avec votre Conseiller Formation.</p>
      </div>
    </div>
    <div class="bg-midnightblue">      
      <div class="home-block-title home-block-title-dark padding-30">
        <div>
          <font-awesome-icon class="white m-left vertical-center-2" :icon="['fas', 'cog']" size="3x"/>
        </div>
        <h2 class="dark text-uppercase margin-left-10">Fonctionnement</h2>
      </div>
      <div class="home-block-body padding-10">
        <ul ref="step-list">
          <li class="arrow-block">
            <div class="number-circle">1</div>
            <h3 class="text-uppercase arrow-block-title">Plongez dans le monde de l'innovation</h3>
            <p>Commencez par vous identifier et accéder à un quiz rapide et ludique pour entrer dans l'Innovation.</p>
            <p>Nous vous demandons des informations sur vous et votre entreprise afin de vous offrir la possibilité d'être recontacté par un conseiller pour une analyse des résultats plus détaillée.</p>
            <p>Ces informations nous permettront également de vous envoyer votre code de reconnexion, à utiliser si vous souhaitez revenir sur le questionnaire en cours, ou visualiser vos résultats.</p>
          </li>
          <li class="arrow-right d-none d-lg-block" v-bind:style="styleArrow"></li>
          <li class="arrow-left  d-none d-lg-block" v-bind:style="styleArrow"></li>
          <li class="arrow-block">
            <div class="number-circle">2</div>
            <h3 class="text-uppercase arrow-block-title">Réalisez votre auto-diag</h3>
            <p>Vous accédez à votre auto-diagnostic après avoir sélectionné vos centres d'intêrets.</p>
            <p>Une trentaine d'innovations vont vous être proposées, sur lesquelles vous positionnerez votre pratique à l'aide d'une échelle de valeurs.</p>
          </li>
          <li class="arrow-right d-none d-lg-block" v-bind:style="styleArrow"></li>
          <li class="arrow-left  d-none d-lg-block" v-bind:style="styleArrow"></li>
          <li class="arrow-block">
            <div class="number-circle">3</div>
            <h3 class="text-uppercase arrow-block-title">Visualisez vos résultats et envisagez demain</h3>
            <p>
              <span class="span-line">Une fois le diagnostic terminé, vous accédez à une cartographie complète des résultats : </span>
              <span class="span-line">vos forces, votre positionnement, par rapport aux autres entreprises du secteur, vos axes d'amélioration.</span>
            </p>
            <p>
              <span class="span-line">Besoin d'aide ?</span>
              <span class="span-line">N'hésitez pas à faire appel à votre Conseiller Formation, il vous proposera un rendez-vous téléphonique ou physique pour échanger ensemble sur vos sujets importants et vous proposer les solutions Constructys adaptées à vos enjeux.</span>
            </p>
          </li>
          <li class="arrow-right d-none d-lg-block" v-bind:style="styleArrow"></li>
        </ul>
      </div>
    </div>
    <div class="container-round-gray home-block">
      <div class="home-block-body row">
        <div :class="'col-12 ' + (youtubeLinks.length > 0 ? 'col-xl-5' : '') +' white-right-border'">
          <div class="col-md-12 bg-whitesmoke padding-10">
            <div class="home-block-title">
              <div>
                <font-awesome-icon class="blue m-left vertical-center-2" :icon="['fas', 'play-circle']" size="3x"/>
              </div>
              <h2 class="bright text-uppercase margin-left-10">Démarrer le diagnostic</h2>
            </div>
          <p>Afin de définir au mieux la situation de votre entreprise et ses axes de progression, nous vous proposons d'entrer, dès maintenant, dans le monde de l'innovation avec un quiz.</p>
          </div>
          <div class="col-md-12 button_home">
            <button id="button_start_diagnosis" class="bg-orangered white" @click="openContactModal()">
              <div class="text-uppercase font-weight-bold"><span>Je démarre</span> <span>un nouveau diagnostic</span></div>
              <font-awesome-icon class="m-left vertical-center-2 white" :icon="['fas', 'play-circle']" size="3x"/>
            </button>
          </div>
          <div class="col-md-12 button_home">
            <button id="button_resume_diagnosis" class="bg-blue text-uppercase white" @click="openResumeModal()">Continuer un diagnostic</button>
          </div>
          <div class="col-md-12 button_home">
            <button id="button_result_diagnosis" class="bg-midnightblue text-uppercase white" @click="openResultModal()">Consulter un diagnostic</button>
          </div>
        </div>
        <div class="col-12 col-xl-7 block-video row">
          <div class="col-12 col-lg-6" v-for="youtubeLink in youtubeLinks" :key="youtubeLink">
            <iframe class="responsive-iframe"
              :src="'https://www.youtube-nocookie.com/embed/'+youtubeLink+'?modestbranding=1&rel=0'" allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>

    <ContactForm ref="contactForm" />

    <footer-home></footer-home>

    <b-modal ref="resume-diagnosis-modal" class="bg-darkorange" size="lg" :centered="!mobile" id="resume-diagnosis-modal" cancel-disabled ok-disabled>
      <template #modal-header="{ close }">
        <p class="text-uppercase create-diagnosis-title text-orange"></p>
        <font-awesome-icon class="cursor-pointer m-left float-right blue" @click="close()" :icon="['far', 'times-circle']" size="2x"/>
      </template>
      <p class="text-uppercase blue" id="contact-modal-subtitle">Continuer un diagnostic</p>
      <p class="label-contact-form">Bonjour ! Prêt à terminer votre</p>
      <p class="blue"><span class="font-weight-bold">DIAG</span>-INNOV-TP</p>
      <b-form  v-on:submit.prevent class="form-contact">
        <label for="input-code" class="label-contact-form">Veuillez saisir le code que vous avez reçu par mail lors de votre inscription :</label>
        <b-input-group class="input-group-code">
          <input class="bg-blue form-control"  id="input-code" v-model="form.code" type="text" max="9" placeholder=" _ _ _ _ _ _ _ " v-on:keyup.enter="onSubmitResumeForm" />
          <b-input-group-append>
            <b-button class="bg-midnightblue" @click="onSubmitResumeForm">
              <font-awesome-icon class="m-left vertical-center-2" :icon="['fas', 'play-circle']" size="2x" :style="{color: 'white' }"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <p v-if="error" class="error col-md-12">
        {{error}}
      </p>
      <template #modal-footer>
        <p></p>
      </template>
    </b-modal>

    <b-modal ref="result-diagnosis-modal" class="bg-darkorange" size="lg" :centered="!mobile" id="result-diagnosis-modal" cancel-disabled ok-disabled>
      <template #modal-header="{ close }">
        <p class="text-uppercase create-diagnosis-title text-orange"></p>
        <font-awesome-icon class="cursor-pointer m-left float-right blue" @click="close()" :icon="['far', 'times-circle']" size="2x"/>
      </template>
      <p class="text-uppercase blue" id="contact-modal-subtitle">Consulter un diagnostic terminé</p>
      <p class="label-contact-form">Vous pouvez consulter votre dernier diag en saisissant le code que vous avez reçu par mail lors de votre inscription : </p>
      <b-form  v-on:submit.prevent class="form-contact">
        <b-input-group class="input-group-code">
          <input class="bg-blue form-control"  id="input-code" v-model="form.code" type="text" max="9" placeholder=" _ _ _ _ _ _ _ " v-on:keyup.enter="onSubmitResultForm"/>
          <b-input-group-append>
            <b-button class="bg-midnightblue" @click="onSubmitResultForm">
              <font-awesome-icon class="m-left vertical-center-2" :icon="['fas', 'play-circle']" size="2x" :style="{color: 'white' }"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <span v-if="error" class="error col-md-12">
        {{error}}
      </span>
      <template #modal-footer>
        <p></p>
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/form/ContactForm";
import Footer from "@/components/common/Footer";
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "../api-requests"

export default {
  name: 'Home',
  components: {
    ContactForm,
    'footer-home': Footer
  },
  data() {
    return {
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
      form: {
        code: ""
      },
      styleArrow: {
        'border-bottom-width': "0px",
        'border-top-width': "0px"
      },
      error: null,
      userValidateChoice: false,
      youtubeLinks: []
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    }
  },
  methods: {
    getYoutubeLinks() {
        API.getYoutubeLinks().then(response => {
          this.youtubeLinks=response.data;
				});
    },
    openContactModal() {
      this.$refs.contactForm.show();
    },
    openResumeModal() {
      this.error = null;
      this.userValidateChoice = false;
      this.$refs['resume-diagnosis-modal'].show();
    },
    openResultModal() {
      this.error = null;
      this.userValidateChoice = false;
      this.$refs['result-diagnosis-modal'].show();
    },
    onSubmitResumeForm() {
      if(this.form.code.length == 8) {
        API.getDiagnosisByCode(this.form.code)
          .then(response => {  
            if(response.data.id == 0) {
              this.error = "Impossible de trouver de diagnostic avec ce code";
            } else {
              this.$store.commit("updateDiagnosis", response.data);
              switch(response.data.status) {
                case 1: this.$router.push({ name: 'quizz', params: { code: this.form.code } });break;
                case 2: this.$router.push({ name: 'diagnosis', params: { code: this.form.code } });break;
                case 3: if(this.userValidateChoice) {
                  this.$router.push({ name: 'diagnosis', params: { code: this.form.code } });
                } else {
                  this.userValidateChoice = true;
                  this.error="Vous souhaitez continuer un diagnostic déjà terminé. Si vous voulez voir le résultat de ce diagnostic appuyer à nouveau sur le bouton.";
                }
                break;
                default:
            }
            
          }
        })  
        .catch((err) => {
          if(err.response.status == 400) {
            this.error = "Le serveur est indisponible pour le moment, merci de réessayer dans quelques minutes";
          } else {
            this.error = "Le code que vous avez rentré est incorrect";
          }
        })  
        .finally(() => this.loading = false) 
      } else {
        this.error = "Le code doit contenir 8 caractères";
      }
    },
    onSubmitResultForm() {
      if(this.form.code.length == 8) {
        API.getDiagnosisByCode(this.form.code)
          .then(response => {  
            if(response.data.id == 0) {
              this.error = "Impossible de trouver de diagnostic avec ce code";
            } else {
              this.$store.commit("updateDiagnosis", response.data);
              switch(response.data.status) {
                case 1: 
                  if(this.userValidateChoice) {
                    this.$router.push({ name: 'quizz', params: { code: this.form.code } });
                  } else {
                    this.userValidateChoice = true;
                    this.error="Vous souhaitez consulter le résultat d'un diagnostic en cours. Si vous voulez continuer ce diagnostic appuyer à nouveau sur le bouton.";
                  }
                break;
                case 2:
                  if(this.userValidateChoice) {
                    this.$router.push({ name: 'diagnosis', params: { code: this.form.code } });
                  } else {
                    this.userValidateChoice = true;
                    this.error="Vous souhaitez consulter le résultat d'un diagnostic en cours. Si vous voulez continuer ce diagnostic appuyer à nouveau sur le bouton.";
                  }break;
                case 3: this.$router.push({ name: 'diagnosis', params: { code: this.form.code } });break;
                default:
              }
            }
          })  
        .catch((err) => {
          if(err.response.status == 400) {
            this.error = "Le serveur est indisponible pour le moment, merci de réessayer dans quelques minutes";
          } else {
            this.error = "Le code que vous avez rentré est incorrect";
          }
        })  
        .finally(() => this.loading = false) 
      } else {
        this.error = "Le code doit contenir 8 caractères";
      }

    },
    resizeEvent() {
      this.styleArrow = {
        'border-bottom-width': 0 + "px",
        'border-top-width': 0 + "px"
      };
    }
  },
  created() {
    this.getYoutubeLinks();
    window.addEventListener("resize", this.resizeEvent);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  mounted() {
    this.resizeEvent();
  },
  updated() {
    
    if(this.styleArrow['border-top-width'] == "0px") {
      let height = this.$refs['step-list'].getBoundingClientRect().height;
      this.styleArrow = {
        'border-bottom-width': height/2 + "px",
        'border-top-width':height/2 + "px"
      }
    }
  }
}
</script>

<style lang="scss">

.min-vh-100{
  min-height: 100vh!important;
}

.stick-left{
  display:inline-block;
  width:250px;
}

.stick-right{
  display:inline-block;
  width: calc(100% - 250px);
  overflow:hidden;
}
    .toggle-off{
        display:none
    }

    .collapsed > .toggle-on {
        display: none;
    }

    .collapsed > .toggle-off{
        display:initial;
    }

    .vertical-center{
        line-height:2em;
        vertical-align:middle;
    }
    
    .container-fluid-mobile{
      min-height:100vh;
      width:100%;
      background-color:white;
    }

    .home-block {
      padding:10px;
      margin: 20px;
    }

    .home-block-title {
      display: flex;
    }

    .home-block-title div {
      line-height: 3.5;
    }

    .home-block-title-dark {
      padding: 10px 30px;
    }

    .subtitle {
      font-size:30px;
      font-weight: 200;
      margin-left:10px;
    }

    .font-weight-700 {
      font-weight: 700;
    }

    .font-weight-500 {
      font-weight: 500;
    }

    .title-home {
      width: fit-content;
    }

    .title{
      font-size: 3em;
    }

    .create-diagnosis-title {
      margin: auto;
    }

    .modal-header, .modal-footer {
      border: 0;
      padding-bottom:0;
    }

    .modal-content {
      border: 1px solid $blue;
      border-radius: 0;
      font-weight:300;
      font-size: 1.3rem;
    }

    .modal-body {
      padding-top: 0;
      
    }

    #contact-modal-subtitle {
      text-align: center;
    }

    .home-block-body>p,  .home-block-body>div>p{
      padding-left: 15px;
      font-weight: 500;
    }

    .home-block-body ul {
      margin-top:30px;
      display:flex;
      justify-content: center;
    }

    .home-block-body {
      justify-content: center;
    }



    .arrow-right {
      border-top: solid transparent;
      border-left: 30px solid white;
      border-bottom: solid transparent;
      list-style-type: none;
    }

    .arrow-left {
      background-color: white;
      border-top: solid transparent;
      border-left: 30px solid $midnightblue;
      border-bottom: solid transparent;
      list-style-type: none;
    }

    .arrow-block p{
      font-size: 0.8em;
    }

    .arrow-block {
      background-color: white;
      width: 25%;
      max-width: 370px;
      list-style-type: none;
      padding:40px 20px 20px 20px;
      position: relative;
    }

    .number-circle {
      position: absolute;
      left: 20px;
      top: -35px;
      width: 4rem;
      height: 4rem;
      border: solid 5px $orangered;
      background-color: white;
      border-radius: 50%;
      font-size: 2.2rem;
      text-align: center;
      font-weight: 900;
      color: $orangered;
    }

    .button_home {
      text-align: center;
      margin: 20px 0px;

      button {
        font-size: 1em;
        padding: 10px;
        width:66%;
        border:0;
      }
    }

    #button_start_diagnosis {
      display:inline-flex;
      align-items: center;
      justify-content: space-around;
    }

    #button_start_diagnosis:hover, #button_start_diagnosis:active, #button_start_diagnosis:focus{
      background-color: rgb(233,94,39);
      border-color: rgb(233,94,39);
    }

    #button_resume_diagnosis:hover, #button_resume_diagnosis:active, #button_resume_diagnosis:focus{
      background-color: rgb(45,73,148);
      border-color: rgb(45,73,148);
    }

    #button_result_diagnosis:hover, #button_result_diagnosis:active, #button_result_diagnosis:focus{
      background-color: rgb(19,38,90);
      border-color: rgb(19,38,90);
    }

    .bg-steelblue {
      background-color: rgb(65,93,168);
    }

    .bg-darkorange {
      background-color: rgb(241,135,0);
    }
    
    .bg-chocolate {
      background-color: rgb(233,94,39);
    }

    .white-right-border {
      border-right: 2px solid white;
    }

    #resume-diagnosis-modal .modal-body {
      text-align:center;
    }

    #result-diagnosis-modal .modal-body {
      margin: 30px;
      text-align:center;

      p {
        margin-bottom: 50px;
      }
    }

  .error{
    color: red;
    margin-bottom: 0;
  }


    @media all and (max-width: 960px) {
    .arrow-block {
      margin-top:50px;
      width: 100%;
      align-self:center;
    }

    .home-block-body ul{
      flex-direction:column;
      padding-left:0px;
      margin-top:0px;
    }

    .title{
      font-size:1.1em;
      font-weight:bold;
    }

    .subtitle{
      font-size:1.1em;
      font-weight:bold;
    }

    .button_home button{
      width:100%;
    }

    #img-footer{
      width:100%;
    }

    .modal-dialog{
      width: 100%;
      height:100vh!important;
      max-width:100%!important;
      border:none;
      margin:0;
    }

    .modal-content {
      border:none;
      border-radius:0px;
    }

    .input-group-code{
      width:100%;
    }
  }

  .padding-10 {
    padding:10px;
  }

  .width-50 {
    width: 50px;
  }

  .width-90 {
    width: 90px;
  }

  .block-video {
    justify-content:space-evenly;
    div{
      height:100%;
    }

    @media all and (max-width: 991px) {
      div{
        height:376px;
      }
    }

    @media all and (max-width: 425px) {
      div{
        height:196px;
      }
    }

    @media all and (min-width: 992px) {
      min-height:376px;
    }
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 95%;
    height: 95%;
    margin:auto;
  }
</style>

<style scoped lang="scss">
  @import '../css/theme.css';

    body{
        height:100%;
    }

  .label-contact-form{
    font-size: 1rem;
    font-weight: 500;
  }

  .input-group-code {
    margin: auto;
    width:50%;
  }

  #input-code {
    color: $white;
    text-align:center;
    height:50px;
  }

  #input-code::placeholder{
      color: $white;
    }

  .span-line {display: block}

  .arrow-block-title {
    min-height: 50px;
  }

  .input-group-code{
    input {
      font-weight: 800;
      border: 0;
      border-radius: 0;
      letter-spacing: 5px;
    }

    input::placeholder {
      font-weight: 900;
    }

    input:focus {
      background-color: $midnightblue;
    }

    .input-group-append{
      button {
        border: 0;
        border-radius: 0;
      }
    }
  }

  .bg-chocolate:focus, .bg-chocolate:hover, .bg-chocolate:active{
    background-color: rgb(209, 65, 8)!important;
  }

  #icon-play {
    margin-top:85px;
  }

  #text-mention {
    text-align:center;
  }

    @media all and (max-width: 767px) {
    .input-group-code {
      width:80%;
    }
  }

  .margin-left-10 {
    margin-left: 10px;
  }
</style>
