import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTrashRestore,
    faRedo,
    faEraser,
    faWindowClose,
    faEye,
    faBell,
    faEyeSlash,
    faShoppingCart,
    faUser,
    faLock,
    faCalendarCheck,
    faCalendarAlt,
    faPlus,
    faBuilding,
    faSearchLocation,
    faFileMedicalAlt,
    faTools,
    faFileSignature,
    faUserPlus,
    faUserMinus,
    faUserSlash,
    faHouseDamage,
    faUsers,
    faPen,
    faTrash,
    faCheck,
    faTimes,
    faCaretDown,
    faCaretUp,
    faSearch,
    faCaretRight,
    faFileImage,
    faFilePdf,
    faArrowAltCircleRight,
    faArrowAltCircleLeft,
    faPlusCircle,
    faSortAmountUp,
    faSortAmountDown,
    faExclamationCircle,
    faImage,
    faImages,
    faKey,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faMinusCircle,
    faAt,
    faEnvelope,
    faStarHalfAlt,
    faUndoAlt,
    faComment,
    faHome,
    faFolderOpen,
    faSignOutAlt,
    faArrowUp,
    faBookmark,
    faSortUp,
    faSortDown,
    faSort,
    faMinus,
    faFilter,
    faQuestionCircle,
    faDownload,
    faCaretLeft,
    faFileExcel,
    faCheckDouble,
    faUserTag,
    faUserTimes,
    faClipboardList,
    faDoorOpen,
    faCalendar,
    faCompass,
    faArrowLeft,
    faCog,
    faPlayCircle,
    faPlay,
    faExclamationTriangle,
    faFlag,
    faBullseye,
    faHeadset
} from '@fortawesome/free-solid-svg-icons';


import { faBookmark as faRegularBookmark, faSmile,faFrown, faMehBlank} from '@fortawesome/free-regular-svg-icons';
import {faFacebook, faLinkedinIn, faYoutube, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons';

library.add(
    faUsers,
    faTrashRestore,
    faRedo,
    faEraser,
    faWindowClose,
    faEye,
    faBell,
    faEyeSlash,
    faShoppingCart,
    faUser,
    faLock,
    faCalendarCheck,
    faCalendarAlt,
    faPlus,
    faBuilding,
    faSearchLocation,
    faFileMedicalAlt,
    faTools,
    faFileSignature,
    faBookmark,
    faUserPlus,
    faUserMinus,
    faUserSlash,
    faHouseDamage,
    faPen,
    faTrash,
    faCheck,
    faTimes,
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faSearch,
    faCaretRight,
    faFileImage,
    faFilePdf,
    faArrowAltCircleRight,
    faArrowAltCircleLeft,
    faPlusCircle,
    faSmile,
    faFrown,
    faSortAmountUp,
    faSortAmountDown,
    faExclamationCircle,
    faImage,
    faImages,
    faKey,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faMinusCircle,
    faAt,
    faEnvelope,
    faStarHalfAlt,
    faUndoAlt,
    faComment,
    faHome,
    faFolderOpen,
    faSignOutAlt,
    faArrowUp,
    faRegularBookmark,
    faSortUp,
    faSortDown,
    faMinus,
    faFilter,
    faQuestionCircle,
    faDownload,
    faSort,
    faFileExcel,
    faCheckDouble,
    faUserTag,
    faMehBlank,
    faUserTimes,
    faClipboardList,
    faDoorOpen,
    faCalendar,
    faCompass,
    faArrowLeft,
    faCog,
    faPlayCircle,
    faPlay,
    faExclamationTriangle,
    faFlag,
    faBullseye,
    faHeadset,
    faLinkedinIn,
    faYoutube,
    faTwitter,
    faFacebook,
    faInstagram
);
