<template>
  <div>
    <div v-if="!loadingQuestions">
      <div class="block-title bg-midnightblue">
        <h1><span class="font-weight-bold">QUIZ</span>-INNOV-TP</h1>
      </div>

    <component-quizz-progression v-if="quizzLaunched" :questions="questions" :current-question="currentQuestion" :answering="answering"></component-quizz-progression>

    <component :is="currentComponent" @launchQuizzEvent="launchQuizz()" @updateAnsweringEvent="updateAnswering" @nextQuestion="nextQuestion" @quizzDone="quizzDone" :questions="questions" :current-question="currentQuestion" :answering="answering"></component>

    <div v-if="done" class="row quizzDone">
      <div class="col-12 col-md-6 col-lg-5 text-uppercase text-congrat blue">
        <span id="congrat" v-if="nbRightAnswer() > 0">Félicitations</span>
        <span>Vous obtenez votre</span>
        <span>{{getMsgMedal()}}</span>
      </div>
      <div class="col-12 col-md-6 col-lg-2 img-medal">
          <img :src="getImgMedal()" :alt="getMsgMedal()"/>
      </div>
      <div class="col-12 col-lg-5">
        <b-button class="bg-chocolate text-uppercase text-diagnosis-button" @click="openModalAxes">
          <span>Parfait !</span>
          <span>Démarrons maintenant</span>
          <span>votre diagnostic !</span>
        </b-button>
      </div>
    </div>

    <b-modal ref="axes-modal" size="lg" :centered="!mobile" id="resume-diagnosis-modal" cancel-disabled ok-disabled no-close-on-backdrop>
      <template #modal-header>
        <p></p>
      </template>
      <p class="text-uppercase blue" id="axes-modal-subtitle">JE DÉFINIS MES CENTRES D'INTÉRÊTS AFIN D'AVOIR UN DIAGNOSTIC AU PLUS PRÈS DE MES BESOINS : </p>
      <b-form class="form-axes">
            <b-form-checkbox-group
              v-model="axesSelected"
              :options="axesOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              id="checkbox-axes"
            ></b-form-checkbox-group>
            <p class="info-axes blue">Il est possible de tout sélectionner</p>
            <p v-if="error" class="error col-md-12">
              {{error}}
            </p>
            <b-button @click="onSubmitAxesForm" id="button-axes" class="text-uppercase col-md-6">Je valide mes choix</b-button>
      </b-form>

      <template #modal-footer>
        <p></p>
      </template>
    </b-modal>
  </div>
  <div class="col-md-12 spinnerQuestion" v-else>
    <b-spinner></b-spinner>
  </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import QuizzHome from '../components/quizz/QuizzHome.vue';
import QuizzQuestion from '../components/quizz/QuizzQuestion.vue';
import QuizzProgression from '../components/quizz/QuizzProgression.vue';
import API from "../api-requests"

export default {
  name: 'Quizz',
  components: {
	'component-quizz-home': QuizzHome,
	'component-quizz-question': QuizzQuestion,
  'component-quizz-progression': QuizzProgression,
  },
  data() {
    return {
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
      currentComponent: 'component-quizz-home',
      diagnosisCode: this.$route.params.code,
      currentQuestion: 0,
      quizzLaunched: false,
      done: false,
      answering: true,
      error: null,
      loadingQuestions: false,
      axesOptions: [],
      axesSelected: [],
      dates: [
        {year: 2019, image: {name:"img-auto",ratio:0.8}, text: "Premier véhicule autonome pour le marquage au sol en France", title: null},
        {year: 2007, image: {name:"img-phone",ratio:1}, text: null, title: "Premier Smartphone sur un chantier"},
        {year: 1997, image: {name:"img-bim",ratio:1}, position: null, title: "Création du premier format BIM, IFC 0.1"},
        {year: 2004, image: {name:"img-eurocode",ratio:1}, position: null, title: "Première publication des Eurocodes en France"},
        {year: 2020, image: {name:"img-imprim",ratio:1}, position: null, title: "Première ouvrage imprimé en 3D en Europe"},
      ],
      questions: [],
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    },
    diagnosis() {
      return this.$store.state.diagnosis;	
    }
  },
  methods: {
    launchQuizz() {
      this.currentComponent = 'component-quizz-question';
      this.quizzLaunched = true;
      this.answering = true;
    },
    toDiagnosis() {
      this.$router.push({ name: 'diagnosis', params: { code: this.diagnosisCode } });
    },
    updateAnswering(bool) {
      this.answering = bool;
    },
    nextQuestion() {
      this.currentQuestion++;
    },
    quizzDone() {
      this.done = true;
    },
    openModalAxes() {
      this.$refs["axes-modal"].show();
    },
    getAxes() {
      API.getAxes().then(response => {
        for(const axis of response.data) {
          this.axesOptions.push({item: axis.id, name: axis.name});
        }
      });
    },
    onSubmitAxesForm() {
      let axesDiagnosis = [];
      for(const axis of this.axesSelected) {
        axesDiagnosis.push({id: axis, diagnosisId: this.diagnosis.id})
      }
      if(axesDiagnosis.length == 0) {
        this.error = "Veuillez sélectionner au moins un axe";
        return;
      }
      this.loadingQuestions = true;

      if(this.diagnosis.status == 1) {
        API.increaseStatus(this.diagnosis).then(response => {
          this.$store.state.diagnosis.status = response.data.status;
        });
      }

      API.setAxesDiagnosis(axesDiagnosis).then(() => {
        API.getDiagnosisQuestions(this.diagnosisCode, true).then(response => {
          this.$store.state.diagnosis.diagnosisQuestions = response.data;
          this.$router.push({ name: 'diagnosis', params: { code: this.diagnosisCode } });
        });
      });
      
    },
    nbRightAnswer() {
      let count = 0;
      for(const question of this.questions) {
        if(question.status == 1) {
          count++;
        }
      }
      return count;
    },
    getImgMedal() {
      //var images = require.context('../assets/icons/', false, /\.svg$/);
      let count = this.nbRightAnswer();
      if(count == this.questions.length - 1) {
        //return images("./BADGE INNOVATION OR.svg");
        return require('../assets/icons/goldenBadge.svg');
      } else if (count >= (this.questions.length-1) / 2) {
        //return images("./BADGE INNOVATION ARGENT.svg");
        return require('../assets/icons/silverBadge.svg');
      } else {
        //return images("./BADGE INNOVATION BRONZE.svg");
        return require('../assets/icons/bronzeBadge.svg');
      }
    },
    getMsgMedal() {
      let count = this.nbRightAnswer();
      if(count == this.questions.length - 1) {
        return "BADGE INNOVATION D'OR";
      } else if (count >= (this.questions.length-1) / 2) {
        return "BADGE INNOVATION D'ARGENT";
      } else {
        return "BADGE INNOVATION DE BRONZE";
      }
    }
  },
  mounted() {
    this.getAxes();
    if(this.diagnosis == null || this.diagnosis.code != this.diagnosisCode) {
      this.$store.dispatch("updateDiagnosisWithAPI", this.diagnosisCode);
      this.diagnosis = this.$store.state.diagnosis;
    }

    for(let i=1; i < this.dates.length; i++) {
      //On calcule la position (le n-ième bloc sur lequel il faudra cliquer pour avoir la bonne réponse)
      //Et on remplit le tableau de dates qu'il y'aura dans la question en cours
      //Pour la première question l'image de la date sera toujours remplacé par un texte (Qui doit exister du coup)
      let position = 0;
      let dates = [];
      const currentDate = this.dates[i];
      for(let j = 0; j < i; j++) {
        dates.push(this.dates[j]);
        if(this.dates[j].year < currentDate.year) {
          position++;
        }
      }
      dates.sort((a,b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0))

      this.questions.push({number: ""+i, title: currentDate.title, answer: {position: position, image: currentDate.image, year: currentDate.year}, status: 0, dates: dates});
    }
    this.questions.push({dates:this.dates.sort((a,b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0))});
  }
}
</script>

<style lang="scss">
  #congrat {
    font-weight: 700;
    font-size: 2em;
    overflow-wrap: break-word;
  }

  img{
    max-width:100vw;
  }

  .text-diagnosis-button {
    color: white;
    font-size: 1.1em;
    font-weight: 300;
    display:grid;
    justify-content: center;
    margin: auto;
    width: 80%;
    border-radius: 0;
    border:none;
    box-shadow: 1.5px 1.5px 10px #707070
  }

  .text-diagnosis-button:hover, .text-diagnosis-button:focus, .text-diagnosis-button:active {
      background-color: $orangeredHover!important;
      border:none!important;
      color: white;
	}

  .text-congrat{
    display: flex;
    flex-flow:column;
    font-size:1.5em;
    font-weight: 300;
    padding-left: 6%;
    line-height: 1.2;
  }
  
  @media all and (max-width: 960px) {
    #button-launch-quizz {
      font-size:1em;
      padding:10px;
      width:90%;
    }

    #title-question-block span{
      font-size:1em!important;
    }

    #congrat {
      font-size: 1.8em;
    }

    .text-congrat {
      font-size: 1.3em;
    }

    .text-diagnosis-button {
      font-size: 1em;
      margin-bottom:10px;
    }

    .img-medal {
      text-align:center;
      img {
        width:10em;
      } 
    }

    .quizzDone{
      justify-content: center;
    }
  }

  @media all and (max-width: 960px) and (min-width: 768px) {
    .text-congrat {
      text-align:left;
      padding-left:12%;
    }
  }

  @media all and (max-width: 767px) {
    .text-congrat {
      text-align:center;
      padding-left:0;
    }
  }

  #checkbox-axes {
    display: inline-grid;
    
    .custom-control-inline {
      text-align: left;
    }
  }

</style>

<style scoped lang="scss">
  @import '../css/theme.css';



  #button-axes{
    background-color: rgb(69,156,72);
    color: white;
    border-radius: 0;
    padding:10px;
  }

  .quizzDone {
    align-items: center;
    margin: 0;
  }

  @media all and (min-width: 960px) {
      .quizzDone {
        margin-top: -50px;
      }
    }
  

  .error{
    color: red;
  }

  .spinnerQuestion {
    text-align: center;
    padding-top: 45vh;
  }

  .info-axes {
    font-size:0.7em;
  }


</style>
