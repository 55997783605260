<template>
	<b-modal ref="result-mail-modal" size="lg" :centered="!mobile" id="result-mail-modal" cancel-disabled ok-disabled>
      <template #modal-header="{ close }">
        <p class="text-uppercase result-mail-title blue">Je contacte mon conseiller formation</p>
        <font-awesome-icon class="cursor-pointer blue m-left float-right" @click="close()" :icon="['far', 'times-circle']" size="2x"/>
      </template>
      <p class="text-uppercase" id="mail-modal-subtitle">Mes informations : </p>
      <b-form v-on:submit.prevent class="form-mail row">
        <p v-if="error" class="error col-md-12">
          {{error}}
        </p>
        <label for="input-phone" class="text-uppercase col-12 col-sm-4 label-mail-form blue mandatory">Nom</label>
        <input class="col-11 col-sm-7 form-control" id="input-name" type="text" v-model="form.name">
        
        <label for="input-job" class="text-uppercase col-12 col-sm-4 label-mail-form blue mandatory">Mail</label>
        <input class="col-11 col-sm-7 form-control" id="input-email" type="text" v-model="form.email">
        
        <label for="input-phone" class="text-uppercase col-12 col-sm-4 label-mail-form blue mandatory">Téléphone</label>
        <input class="col-11 col-sm-7 form-control" id="input-phone" type="text" v-model="form.phoneNumber">
        
        <label for="input-job" class="text-uppercase col-12 col-sm-4 label-mail-form blue mandatory">Région</label>
        <input class="col-11 col-sm-7 form-control" id="input-company-region" type="text" v-model="form.companyRegion">
        <div class="col-12 col-sm-1"></div>

        <div id="mail-form-footer">       
          <button v-if="!loading" id="mail-submit-button" class="bg-orangered text-uppercase white" @click="onSubmit">Envoyer un mail</button>
          <div v-else class="small-spinner"><b-spinner></b-spinner></div>
        </div>
      </b-form>
      <template #modal-footer>
        <p></p>
      </template>
	</b-modal>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "../../api-requests"

export default {
  name: 'ResultMailForm',
  props: ['diagnosis'],
  data() {
    return {
      form: {
          name: this.diagnosis.contact.firstName + ' ' + this.diagnosis.contact.lastName,
          email: this.diagnosis.contact.email,
          phoneNumber: this.diagnosis.contact.phoneNumber,
          companyRegion:''
      },
      error: null,
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
      loading: false,
    }
  },
  computed: {
  },
  methods: {
    onSubmit() {
        if(this.checkForm(this.form) && !this.loading) {
          this.loading = true;
          API.sendResultMail(this.form).then(response => {
            this.$emit('notify-result-mail', response.data);
            this.loading = false;
            this.$refs['result-mail-modal'].hide();
          });
        } else {
          this.loading = false;
        }

    },
    checkForm(form) {
      if(form.name == '' || form.email == '' || form.phoneNumber == '' || form.companyRegion == '') {
        this.error = "Veuillez saisir tous les champs";
        return false;
      }

      const re = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
      if(!re.test(form.email)) {
        this.error = "Adresse email invalide";
        return false;
      }

      this.error = null;
      return true;
    },
    show() {
      this.$refs['result-mail-modal'].show();
    }
  }
}
</script>

<style lang="scss">
    .result-mail-title {
      margin: auto;
      text-align: center;
    }

    
    .dropdown-toggle::after{
      top: calc(50% - 3px);
      position: absolute;
      right: 17px;
    }


    .modal-header, .modal-footer {
      border: 0;
      padding-bottom:0;
    }

    .modal-body {
      padding-top: 0;
      
    }

    #mail-modal-subtitle {
      text-align: center;
    }

    #mail-submit-button {
      margin-top: 15px;
      border:0;
      padding: 10px;
    }

    #mail-submit-button:hover, #mail-submit-button:focus, #mail-submit-button:active {
      background-color: rgb(221,115,0)!important;
      border:none!important;
      color: white;
	}

    #mail-form-footer {
      text-align:center;
      margin: auto;
      max-width:60%;
      font-size: 1rem;
    }

    #asterisk-info {
      font-size: 1rem;
      display: flex;
    }

    .diag-innov {
      font-size: 3rem;
      margin-bottom: 0;
    }

    #input-activities {
      padding:0;

      button{
        border-radius: 0;
      }
    }

    .text-footer {
      font-weight: 500;
      margin-bottom: 0;
      font-style: italic;
    }
</style>

<style scoped lang="scss">
    .form-mail input {
      background-color: rgb(202,216,252);
      display: inline;
      border-radius:0;
    }

    .label-mail-form {
      text-align:right;
      font-size: 1.3rem;
    }

    .error{
      color: red;
      text-align: center;
      margin-bottom:1em;
    }

    .small-spinner{
      margin-top:15px;
    }

    .label-mail-form::after{
        content:" :";
      }

    @media all and (max-width: 767px) {
      .label-mail-form{
        text-align:center;
        margin-top: 10px;
        margin-bottom:0px;
      }
      .mandatory::after{
        content:" *";
        font-weight:bold;
      }

      .result-mail-title {
        font-size: 0.9em;
      }

      #mail-modal-subtitle {
        font-size: 0.9em;
      }

      .form-mail{
        input {
          margin:auto;
          font-size:0.7em;
        }

        #input-activities {
          margin: auto;
        }

        .label-mail-form {
          font-size: 0.7em;
        }

        #mail-form-footer {
          max-width: 90%;
          .diag-innov {
            font-size: 2em;
          }

          .text-footer {
            font-size:0.8em;
          }
        }
      }
    }
    
</style>
