<template>
  <div>
	<div class="diagnosis-title block-title bg-midnightblue row">
		<h1 class="white col-md-9 col-12"><span class="font-weight-bold">DIAG</span>-INNOV-TP</h1>
		<p v-if="begin" class="col-md-2 col-12 question-counter"><span class="counter-left">{{getCurrentQuestionNumber()}}</span><span class="counter-right white">/{{nbQuestions}}</span></p>
	</div>
	<component v-if="nbQuestions > 0" :is="currentComponent" @launchDiagnosisEvent="launchDiagnosis()" @nextQuestionEvent="nextQuestion()" :current-question="currentQuestion" :nb-questions="nbQuestions" :diagnosis="diagnosis"></component>
	<footer-result v-if="diagnosis.status == 3"></footer-result>

	<canvas hidden id="canvas-export"></canvas>
	<b-toast id="pdf-mail-toast" title="Téléchargement de votre PDF" toaster="b-toaster-top-center" auto-hide-delay="5000">
		Votre bilan au format PDF va vous être envoyé par mail à l'adresse : {{diagnosis.contact != null ?diagnosis.contact.email : ''}}
	</b-toast>

	<b-modal ref="end-diagnosis-modal" size="lg" :centered="!mobile" id="end-diagnosis-modal" cancel-disabled ok-disabled no-close-on-backdrop>
      <template #modal-header>
			<p></p>
      </template>
		<p class="font-weight-bold white">Félicitations ! Vous avez terminé votre DIAG-Innov-TP !</p>
		<p class="white">Nous vous remercions pour votre participation</p>
		<p class="font-weight-bold white">Il est temps de découvrir vos résultats !</p>
		<div class="final-button row">
			<div class="col-md-6">
				<b-button class="col-md-12 button-final-modal bg-white" @click="showResult()">
					<span class="text-button">Je consulte le bilan de mon auto-diagnostic innovation</span>
					<font-awesome-icon class="cursor-pointer m-left vertical-center-2 home-button blue" :icon="['fas', 'eye']" size="3x"/>
				</b-button>
			</div>
			<div class="col-md-6 m-top-lg">
				<b-button class="col-md-12 button-final-modal bg-white" @click="downloadDiagnosis()">
					<span class="text-button">Je télécharge le bilan de mon auto-diagnostic innovation au format PDF</span>
					<font-awesome-icon class="cursor-pointer m-left vertical-center-2 home-button blue" :icon="['fas', 'download']" size="3x"/>
				</b-button>
			</div>
		</div>
      <template #modal-footer>
        <p></p>
      </template>
	</b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import DiagnosisHome from '../components/diagnosis/DiagnosisHome.vue';
import DiagnosisQuestion from '../components/diagnosis/DiagnosisQuestion.vue';
import DiagnosisResult from '../components/diagnosis/DiagnosisResult.vue';
import API from "../api-requests";
import Footer from "../components/common/Footer"
import {Barchart} from "../util/chart-util"

export default {
  name: 'Diagnosis',
  components: {
	'component-diagnosis-home': DiagnosisHome,
	'component-diagnosis-question': DiagnosisQuestion,
	'component-diagnosis-result': DiagnosisResult,
	'footer-result': Footer
  },
  data() {
    return {
		ua: getUA,
		mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
		diagnosisCode: this.$route.params.code,
		begin: false,
		imageLoading: true,
		diagnosisDone: false,
		alreadyDownloaded: false,
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
	},
	diagnosis() {
		return this.$store.state.diagnosis;	
	},
	nbQuestions() {
		return this.$store.state.diagnosis.diagnosisQuestions != null ? this.$store.state.diagnosis.diagnosisQuestions.length : 0;
	},
	currentQuestion: {
		get() {
			return this.$store.state.diagnosis.diagnosisQuestions != null ? this.findCurrentQuestion() : 0;
		}
	},
	currentComponent() {
		if(this.$store.state.diagnosis.status == 3) {
			return 'component-diagnosis-result';
		} else if(this.begin) {
			return 'component-diagnosis-question';
		} else {
			return 'component-diagnosis-home';
		}
	}
  },
  methods: {
	launchDiagnosis() {
		this.begin = true;
	},
	findCurrentQuestion() {
		const questions = this.$store.state.diagnosis.diagnosisQuestions;
		for(let i = 0; i < questions.length; i++) {
			if(!questions[i].responded) {
				return i;
			}
		}
		this.diagnosisDone = true;
		return questions.length - 1;
	},
	nextQuestion() {
		if(this.currentQuestion + 1 == this.nbQuestions && this.diagnosisDone) {
			if(this.diagnosis.status == 2) {
				API.increaseStatus(this.diagnosis).then(() => {});
			}
			this.$refs['end-diagnosis-modal'].show();
		}
	},
	downloadDiagnosis() {
		if(!this.alreadyDownloaded) {
			this.$bvToast.show('pdf-mail-toast');
			this.alreadyDownloaded = true;
			API.getPositionnings(this.diagnosis).then(response => {
				let datas = [];
				let labels = [];
				for(let result of response.data) {
					labels.push("AXE " + result.axis);
					datas.push([result.personal, result.global]);
					result.personal = result.personal == null || result.personal == 0 ? "NC" : Math.round(result.personal * 10) / 10;
					result.target = result.target == null || result.target == 0 ? "NC" : Math.round(result.target * 10) / 10;
					result.global = result.global == null ? 0 : Math.round(result.global * 10) / 10;
				}

				let canvasServer = document.getElementById("canvas-export");
				canvasServer.width = 1280;
				canvasServer.height = 640;

				var barchartExport = new Barchart(
						{
							canvas: canvasServer,
							padding:50,
							gridScale:1,
							gridMax: 5,
							gridColor:"#707070",
							backgroundColor: "#ffffff",
							legends: ["Votre positionnement".toUpperCase(), "Positionnement moyen dans votre domaine d'activité".toUpperCase()],
							data: datas,
							labels: labels,
							colors:["#279ce8","#e95e27"]
						}
					);
					barchartExport.draw();
					
				let imageDataURL = canvasServer.toDataURL('image/png');


				API.downloadPdf(imageDataURL, this.diagnosis.code).then(() => {});
			});
		}
	},
	showResult() {
		this.$store.state.diagnosis.status = 3;
		this.$refs['end-diagnosis-modal'].hide();
		this.begin = false;
		//this.currentQuestion = 0;
	},
	getCurrentQuestionNumber() {
		return this.currentQuestion < this.nbQuestions ? this.currentQuestion+1 : this.nbQuestions;
	}
  },
  mounted() {
	if(this.diagnosis == undefined || this.diagnosis.code != this.diagnosisCode) {
		this.$store.dispatch("updateDiagnosisWithAPI", this.diagnosisCode);
	}
  }
}
</script>

<style lang="scss">
	.diagnosis-title{
		display: flex;
	}
	.diagnosis-title p {
		font-size:40px;
	}
	.question-counter {
		text-align: right;
		color: $steelblue;
	}
	.counter-left {
		font-weight: 900;
	}
	.counter-right {
		color: rgb(233,94,39);
		font-weight: 300;
	}
	.button-final-modal {
		color: gray;
		border: solid 1px white;
		box-shadow: 1px 1px 2px lightgrey;
		font-size: 1.5rem;
		height: 100%;
		display: flex;
		flex-flow: wrap;
		justify-content: center;

		svg {
			align-self: flex-end;
		}
	}
	#end-diagnosis-modal .modal-content {
		text-align:center;
		color: white;
		background-color: $midnightblue;
		border: 1px solid $midnightblue;
		border-radius: 0.6rem;
		font-weight:300;
		font-size: 1.7rem;
	}
	.text-button {
		display: inline-block;
	}

	
@media all and (max-width: 960px) {
  .diagnosis-title{
	align-items:center;
  }

  .m-top-lg{
	margin-top:10px;
  }

   .question-counter{
    margin-top:10px;
  }

  #end-diagnosis-modal .modal-content {
	font-size:1.2em;
  }

  #end-diagnosis-modal{
	height: 100vh;
    border-radius: 0px;
  }
}
</style>

<style scoped>
	@import '../css/theme.css';
</style>
