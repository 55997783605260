<template>
  <div>

    <p class="title color-darkorange">GESTION DES INNOVATIONS</p>

	<div class="buttons-innov">
		<b-button class="">Ajouter une innovation</b-button>
		<b-button v-if="changesExists()" @click="saveInnovations()">Sauvegarder tout</b-button>
	</div>

	<b-table id="innovation-table" :items="innovations" :fields="fields">
		<template v-slot:cell(title)="data">
			<b-form-input
				type="text"
				v-model="data.item.title"
				@change="checkInnovationChange(data.item)"
			/>
		</template>
		<template v-slot:cell(complementaryInfo)="data">
			<b-form-input
				type="text"
				v-model="data.item.complementaryInfo"
				@change="checkInnovationChange(data.item)"
			/>
		</template>
		<template v-slot:cell(axis)="data">
			<b-form-select v-model="data.item.axis" :options="axes" @change="checkInnovationChange(data.item)"></b-form-select>
		</template>
		<template v-slot:cell(activities)="data">
			<b-dropdown id="input-activities" text="Sélectionner">
				<b-dropdown-form class="w-200">
                    <div v-if="checkAllCheck(data.item.activities)"  class="check-uncheck" @click="uncheckAllActivities(data.item.activities); checkInnovationChange(data.item)">Tout décocher</div>
                    <div v-else  class="check-uncheck" @click="checkAllActivities(data.item.activities); checkInnovationChange(data.item)">Tout cocher</div>
					<div v-for="(activity,index) in activities" v-bind:key="index" class="dropdown-checkbox">
						<b-form-checkbox
							v-model="data.item.activities[activity.value.code].selected"
							@change="checkInnovationChange(data.item)">
							{{ activity.text }} </b-form-checkbox>
					</div>
				</b-dropdown-form>
			</b-dropdown>
		</template>
		<template v-slot:cell(maturity)="data">
			<b-form-select v-model="data.item.maturity" :options="maturities" @change="checkInnovationChange(data.item)"></b-form-select>
		</template>
		<template v-slot:cell(image)="data">
			<b-form-file :ref="'image-input-' + data.item.id" v-model="data.item.fileToSave" class="innov-img-input" plain accept="image/x-png,image/gif,image/jpeg" @change="checkInnovationChange(data.item)"></b-form-file>
			<div v-if="data.item.image.id == 0 && data.item.fileToSave == null">
				<p>Aucune image</p>
				<b-button @click="openFileDialog(data.item.id)">Ajouter une image</b-button>
			</div>
			<div v-else>
				<img src="rien" />
				<b-button @click="openFileDialog(data.item.id)">Changer l'image</b-button>
			</div> 
		</template>
		<template v-slot:cell(change)="data">
			<b-button v-if="data.item.change" @click="saveInnovation(data.item)">Sauvegarder</b-button>
		</template>
	</b-table>	
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile, compareInnovations } from "@/util/util";
import API from "../api-requests";

export default {
  name: 'AdminInnvation',
  data() {
    return {
		ua: getUA,
		mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
		innovations: [],
		innovationsToCompare: {},
		fields: [
			{key:'id', label:'Id'},
			{key:'title', label:'Titre'},
			{key:'complementaryInfo', label:'Commentaire'},
			{key:'axis', label:'Axe'},
			{key:'activities', label:'Activités'},
			{key:'maturity', label:'Maturité'},
			{key:'image', label:'Image'},
			{key:'change', label:'Actions'},
		],
		axes: [],
		activities: [],
		maturities: [],
    }
  },
  computed: {
    size(){
		if (!this.mobile) return "col-11";
		else return "col-12";
    },
    noGutters(){
		if (!this.mobile) return "";
		else return "no-gutters";
    },
    containerFluid(){
		if (!this.mobile) return "bg-white container-fluid min-vh-100";
		else return "container-fluid-mobile min-vh-100";
    }
  },
  methods: {
	getAxes() {
		API.getAxes().then(response => {
			this.axes = [];
			for(const axis of response.data){
				this.axes.push({value: axis, text: axis.code})
			}
		});
	},
	getActivities() {
		API.getActivities().then(response => {
			this.activities = [];
			for(const activity of response.data){
				this.activities.push({value: activity, text: activity.name})
			}
		});
	},
	getMaturities() {
	API.getMaturities().then(response => {
		this.maturities = [];
		for(const maturity of response.data){
			this.maturities.push({value: maturity.id, text: maturity.code + " : " + maturity.text})
		}
	});
	},
	getInnovations() {
		API.getInnovations().then(response => {
			this.innovations = response.data;
			for(const innov of this.innovations) {
				this.innovationsToCompare[innov.id] = (JSON.parse(JSON.stringify(innov)));
			}
		});
	},
	checkInnovationChange(innovation) {
		innovation.change = !compareInnovations(innovation, this.innovationsToCompare[innovation.id]) || innovation.fileToSave == null;
	},
	checkActivitySelected(activityToCheck, activities) {
		for(const activity of activities) {
			if(activity.id == activityToCheck.id) {
				return true;
			}
		}
		return false;
	},
	checkAllCheck(activities) {
		for (const [, value] of Object.entries(activities)) {
			if(value.selected == false) {
				return false;
			}
		}
		return true;
	},
	checkAllActivities(activities) {
		for (const [, value] of Object.entries(activities)) {
			value.selected = true;
		}
	},
	uncheckAllActivities(activities) {
		for (const [, value] of Object.entries(activities)) {
			value.selected = false;
		}
	},
	openFileDialog(id) {
		this.$refs["image-input-" + id].$el.click();
	},
	saveInnovation(innovation) {
		API.saveInnovation(innovation).then(response => {
			let innov = response.data;
			this.innovationsToCompare[innov.id] = innov;
			innovation.change = false;
		});
	},
	saveInnovations() {
		let innovsToSave = {innovations: []};
		for(const innov of this.innovations) {
			if(innov.change) {
				innovsToSave.innovations.push(innov);
			}
		}
		API.saveInnovations(innovsToSave).then(response => {
			let innovs = response.data;
			for(let innov of innovs) {
				this.innovationsToCompare[innov.id] = innov;
			}
			for(let innov of this.innovations) {
				innov.change = false;
			}
		});
	},
	changesExists() {
		for(const innov of this.innovations) {
			if(innov.change) {
				return true;
			}
		}
		return false;
	},
	createinnovation() {

	}
  },
  mounted() {
	this.getAxes();
	this.getActivities();
	this.getMaturities();
	this.getInnovations();
  }
}
</script>

<style>
</style>

<style scoped>
  @import '../css/theme.css';

  .innov-img-input {
	display: none;
  }
</style>
